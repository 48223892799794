import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type Property1LogOutType = {
  /** Style props */
  property1LogOutPadding?: CSSProperties["padding"];
  property1LogOutOpacity?: CSSProperties["opacity"];
  property1LogOutCursor?: CSSProperties["cursor"];
  property1LogOutBorderW?: CSSProperties["borderWidth"];
  property1LogOutBorderS?: CSSProperties["borderStyle"];
  property1LogOutBorderC?: CSSProperties["borderColor"];
  property1LogOutBackgroundColor?: CSSProperties["backgroundColor"];

  /** Action props */
  onAdditionalClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const SmalllogoutIcon = styled.img`
  position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
  filter: var(--filters-btn-txt);
`;
const LogOut = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
  display: flex;
  align-items: center;
  width: max-content;
  height: 2.083333333333333vh;
  flex-shrink: 0;
  color: var(--colors-btn-txt);
`;
const Property1logoutRoot = styled.div<{
  property1LogOutPadding?: Property.Padding;
  property1LogOutOpacity?: Property.Opacity;
  property1LogOutCursor?: Property.Cursor;
  property1LogOutBorderW?: Property.BorderWidth;
  property1LogOutBorderC?: Property.BorderColor;
  property1LogOutBorderS?: Property.BorderStyle;
  property1LogOutBackgroundColor?: Property.BackgroundColor;
}>`width: 6.25vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-7xsH) 0.69444444444vw;
  box-sizing: border-box;
  gap: var(--gap-5xs);
  opacity: 0.8;
  text-align: left;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-white);
  font-family: var(--buttons-btn-input);
  padding: ${(p) => p.property1LogOutPadding};
  opacity: ${(p) => p.property1LogOutOpacity};
  cursor: ${(p) => p.property1LogOutCursor};
  border-width: ${(p) => p.property1LogOutBorderW};
  border-color: ${(p) => p.property1LogOutBorderC};
  border-style: ${(p) => p.property1LogOutBorderS};
  background-color: ${(p) => p.property1LogOutBackgroundColor};
`;

const Property1LogOut: FunctionComponent<Property1LogOutType> = ({
  property1LogOutPadding,
  property1LogOutOpacity,
  property1LogOutCursor,
  property1LogOutBorderW,
  property1LogOutBorderC,
  property1LogOutBorderS,
  property1LogOutBackgroundColor,
  onAdditionalClick,
}) => {
  return (
    <Property1logoutRoot
      property1LogOutPadding={property1LogOutPadding}
      property1LogOutOpacity={property1LogOutOpacity}
      property1LogOutCursor={property1LogOutCursor}
      property1LogOutBorderW={property1LogOutBorderW}
      property1LogOutBorderC={property1LogOutBorderC}
      property1LogOutBorderS={property1LogOutBorderS}
      property1LogOutBackgroundColor={property1LogOutBackgroundColor}
      onClick={onAdditionalClick}
    >
      <SmalllogoutIcon alt="" src="/LogOut.svg" />
      <LogOut>Log Out</LogOut>
    </Property1logoutRoot>
  );
};

export default Property1LogOut;
