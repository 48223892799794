import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import StateDefaultNotificationno from "./StateDefaultNotificationno";
import styled from "styled-components";

type Property1PrimaryProperty2Type = {
  smallNews?: string;
  children?: React.ReactNode;

  /** Style props */
  property1PrimaryProperty2Width?: CSSProperties["width"];
  property1PrimaryProperty2Margin?: CSSProperties["margin"];
  property1PrimaryProperty2Position?: CSSProperties["position"];
  property1PrimaryProperty2Top?: CSSProperties["top"];
  property1PrimaryProperty2Right?: CSSProperties["right"];
  property1PrimaryProperty2Left?: CSSProperties["left"];
  property1PrimaryProperty2ZIndex?: CSSProperties["zIndex"];
  property1PrimaryProperty2BorderBottom?: CSSProperties["borderBottom"];

  /** Action props */
  onTabPrimaryB2Click?: (e: React.MouseEvent<HTMLElement>) => void;
};

const Property1primaryProperty2Root = styled.div<{
  property1PrimaryProperty2Width?: Property.Width;
  property1PrimaryProperty2Margin?: Property.Margin;
  property1PrimaryProperty2Position?: Property.Position;
  property1PrimaryProperty2Top?: Property.Top;
  property1PrimaryProperty2Right?: Property.Right;
  property1PrimaryProperty2Left?: Property.Left;
  property1PrimaryProperty2ZIndex?: Property.ZIndex;
  property1PrimaryProperty2BorderBottom?: Property.BorderBottom;
}>`border-bottom: 0.13020833333333331vh solid var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 46.38888888888889vw;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-purple-dark);
  font-family: var(--buttons-btn-input);
  width: ${(p) => p.property1PrimaryProperty2Width};
  margin: ${(p) => p.property1PrimaryProperty2Margin};
  position: ${(p) => p.property1PrimaryProperty2Position};
  top: ${(p) => p.property1PrimaryProperty2Top};
  right: ${(p) => p.property1PrimaryProperty2Right};
  left: ${(p) => p.property1PrimaryProperty2Left};
  z-index: ${(p) => p.property1PrimaryProperty2ZIndex};
  border-bottom: ${(p) => p.property1PrimaryProperty2BorderBottom};
`;

const Property1PrimaryProperty2: FunctionComponent<
  Property1PrimaryProperty2Type
> = ({
  smallNews,
  property1PrimaryProperty2Width,
  property1PrimaryProperty2Margin,
  property1PrimaryProperty2Position,
  property1PrimaryProperty2Top,
  property1PrimaryProperty2Right,
  property1PrimaryProperty2Left,
  property1PrimaryProperty2ZIndex,
  property1PrimaryProperty2BorderBottom,
  onTabPrimaryB2Click,
  children,
}) => {
  return (
    <Property1primaryProperty2Root
      property1PrimaryProperty2Width={property1PrimaryProperty2Width}
      property1PrimaryProperty2Margin={property1PrimaryProperty2Margin}
      property1PrimaryProperty2Position={property1PrimaryProperty2Position}
      property1PrimaryProperty2Top={property1PrimaryProperty2Top}
      property1PrimaryProperty2Right={property1PrimaryProperty2Right}
      property1PrimaryProperty2Left={property1PrimaryProperty2Left}
      property1PrimaryProperty2ZIndex={property1PrimaryProperty2ZIndex}
      property1PrimaryProperty2BorderBottom={
        property1PrimaryProperty2BorderBottom
      }
    >
      <StateDefaultNotificationno
        smallFlashCards={smallNews}
        text="Digest"
        stateDefaultNotificationnCursor="unset"
        stateDefaultNotificationnBorderW="unset"
        stateDefaultNotificationnBorderC="unset"
        stateDefaultNotificationnBorderS="unset"
        stateDefaultNotificationnBackgroundColor="unset"
        stateDefaultNotificationnWidth="max-content"
        stateDefaultNotificationnFlexShrink="0"
        stateDefaultNotificationnBoxSizing="border-box"
        stateDefaultNotificationnBorderBottom="unset"
        smallFlashCardsIconOverflow="unset"
        smallFlashCardsIconFlexShrink="unset"
        smallFlashCardsIconOpacity="unset"
        flashCardsDisplay="inline-block"
        flashCardsColor="var(--colors-dark-1)"
        HasClass="selected"
        onTabPrimaryB3Click={onTabPrimaryB2Click}
      />
      {children}
    </Property1primaryProperty2Root>
  );
};

export default Property1PrimaryProperty2;
