import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type StateemptyScrollnoType = {
  /** Style props */
  stateemptyScrollnoBoxSizing?: CSSProperties["boxSizing"];
  stateemptyScrollnoFontFamily?: CSSProperties["fontFamily"];
  stateemptyScrollnoFontSize?: CSSProperties["fontSize"];
  stateemptyScrollnoOutline?: CSSProperties["outline"];
  stateemptyScrollnoColor?: CSSProperties["color"];
  stateemptyScrollnoFlex?: CSSProperties["flex"];
  stateemptyScrollnoHeight?: CSSProperties["height"];
  stateemptyScrollnoFlexShrink?: CSSProperties["flexShrink"];
  stateemptyScrollnoBackgroundColor?: CSSProperties["backgroundColor"];
  stateemptyScrollnoBorderW?: CSSProperties["borderWidth"];
  stateemptyScrollnoBorderS?: CSSProperties["borderStyle"];
  stateemptyScrollnoBorderC?: CSSProperties["borderColor"];
};
const Text1 = styled.textarea`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  resize: none;
  height: 95%;
  border: none;
  font-family: Onest;
  background-color: var(--colors-white);
  color: var(--colors-dark-1);
  &:focus {
    outline: none;
  }
`;
const StateemptyScrollnoRoot = styled.div<{
  stateemptyScrollnoBoxSizing?: Property.BoxSizing;
  stateemptyScrollnoFontFamily?: Property.FontFamily;
  stateemptyScrollnoFontSize?: Property.FontSize;
  stateemptyScrollnoOutline?: Property.Outline;
  stateemptyScrollnoColor?: Property.Color;
  stateemptyScrollnoFlex?: Property.Flex;
  stateemptyScrollnoHeight?: Property.Height;
  stateemptyScrollnoFlexShrink?: Property.FlexShrink;
  stateemptyScrollnoBackgroundColor?: Property.BackgroundColor;
  stateemptyScrollnoBorderW?: Property.BorderWidth;
  stateemptyScrollnoBorderC?: Property.BorderColor;
  stateemptyScrollnoBorderS?: Property.BorderStyle;
}>`border-radius: var(--corner-radius-l);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xsH) var(--padding-5xsW);
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-purple-light);
  font-family: var(--buttons-btn-input);
  align-self: stretch;
  flex: 1;
  box-sizing: ${(p) => p.stateemptyScrollnoBoxSizing};
  font-family: ${(p) => p.stateemptyScrollnoFontFamily};
  font-size: ${(p) => p.stateemptyScrollnoFontSize};
  outline: ${(p) => p.stateemptyScrollnoOutline};
  color: ${(p) => p.stateemptyScrollnoColor};
  flex: ${(p) => p.stateemptyScrollnoFlex};
  height: ${(p) => p.stateemptyScrollnoHeight};
  flex-shrink: ${(p) => p.stateemptyScrollnoFlexShrink};
  background-color: ${(p) => p.stateemptyScrollnoBackgroundColor};
  border-width: ${(p) => p.stateemptyScrollnoBorderW};
  border-color: ${(p) => p.stateemptyScrollnoBorderC};
  border-style: ${(p) => p.stateemptyScrollnoBorderS};
`;

const StateemptyScrollno: FunctionComponent<StateemptyScrollnoType> = ({
  stateemptyScrollnoBoxSizing,
  stateemptyScrollnoFontFamily,
  stateemptyScrollnoFontSize,
  stateemptyScrollnoOutline,
  stateemptyScrollnoColor,
  stateemptyScrollnoFlex,
  stateemptyScrollnoHeight,
  stateemptyScrollnoFlexShrink,
  stateemptyScrollnoBackgroundColor,
  stateemptyScrollnoBorderW,
  stateemptyScrollnoBorderC,
  stateemptyScrollnoBorderS,
}) => {
  return (
    <StateemptyScrollnoRoot
      stateemptyScrollnoBoxSizing={stateemptyScrollnoBoxSizing}
      stateemptyScrollnoFontFamily={stateemptyScrollnoFontFamily}
      stateemptyScrollnoFontSize={stateemptyScrollnoFontSize}
      stateemptyScrollnoOutline={stateemptyScrollnoOutline}
      stateemptyScrollnoColor={stateemptyScrollnoColor}
      stateemptyScrollnoFlex={stateemptyScrollnoFlex}
      stateemptyScrollnoHeight={stateemptyScrollnoHeight}
      stateemptyScrollnoFlexShrink={stateemptyScrollnoFlexShrink}
      stateemptyScrollnoBackgroundColor={stateemptyScrollnoBackgroundColor}
      stateemptyScrollnoBorderW={stateemptyScrollnoBorderW}
      stateemptyScrollnoBorderC={stateemptyScrollnoBorderC}
      stateemptyScrollnoBorderS={stateemptyScrollnoBorderS}
    >
      <Text1 placeholder="Content">
        
      </Text1>
    </StateemptyScrollnoRoot>
  );
};

export default StateemptyScrollno;
