import { FunctionComponent } from "react";
import styles from "./SocialsArea.module.css";

const SocialsArea: FunctionComponent = () => {
  return (
    <div className={styles.socialsArea}>
      <div className={styles.ourSocialsParent}>
        <div className={styles.ourSocials}>Our socials:</div>
        <div className={styles.socialIcons}>
          <div className={styles.iconsSocialTwitter}>
            <img
              className={styles.twitterIcon}
              loading="eager"
              alt=""
              src="/twitter.svg"
            />
          </div>
          <div className={styles.iconsSocialInstagram}>
            <img
              className={styles.mdiinstagramIcon}
              loading="eager"
              alt=""
              src="/mdiinstagram.svg"
            />
          </div>
        </div>
      </div>
      <div className={styles.iconsSocialInstagram1}>
        <img
          className={styles.icbaselineWhatsappIcon}
          loading="eager"
          alt=""
          src="/icbaselinewhatsapp.svg"
        />
      </div>
    </div>
  );
};

export default SocialsArea;
