import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "./global";

const container = document.getElementById("root");
const root = createRoot(container!);
type NewsT = {
  datetime: string;
  categories: string[];
  title: string;
  content: string;
  image: string;
  video: string;
}
const Page: React.FC = () => {
  const [darkMode, setDarkMode] = React.useState(false);
  const [logedIn, _] = React.useState(window.INITIAL_LOG_IN || false);
  const [news, setNews] = React.useState<NewsT[]>([]);
  const [cats, setCats] = React.useState<{"name":string;"id":string}[]>([]);
  const [loading, setLoading] = React.useState(true);
  const level = window.LVL || 0;

  const switchDarkMode = () => {
    setDarkMode(!darkMode);
  };

  React.useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch('/feed', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mode: 'initial' }),
        });
        const data: {"set":NewsT[], "cats":{"name":string;"id":string}[]} = await response.json();
        setNews(data["set"]);
        setCats(data["cats"]);
      } catch (error) {
        console.error('Error fetching news:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchNews();
  }, []);
  const [DisplayedNews, setDisplayedNews] = React.useState<{item:NewsT,index:number}[]>([])
  const [DisplayedTitle, setDisplayedTitle] = React.useState<NewsT>({datetime: "",
    categories: [""],
    title: "",
    content: "",
    image: "",
    video: "",
  })
  const [CurrName, setCurrName] = React.useState("GEN")
  
  return (
    <BrowserRouter>
      <GlobalStyles darkMode={darkMode} />
      <App
        CurrName={CurrName}
        setCurrName={setCurrName}
        DisplayedNews={DisplayedNews}
        setDisplayedNews={setDisplayedNews}
        DisplayedTitle={DisplayedTitle}
        setDisplayedTitle={setDisplayedTitle}
        setNews={setNews}
        darkMode={darkMode}
        switchDarkMode={switchDarkMode}
        News={news}
        LogedIn={logedIn}
        l={level}
        loading={loading}
        cats={cats}
        setCats={setCats}
        setLoading={setLoading} />
    </BrowserRouter>
  );
};

root.render(
  <Page/>
);
