import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import LayoutBlocksLight0 from "./pages/LayoutBlocksLightLVL0";
import LayoutBlocksLight1 from "./pages/LayoutBlocksLightLVL1";
import LayoutBlocksLight2 from "./pages/LayoutBlocksLightLVL2";
import LayoutBlocksLight3 from "./pages/LayoutBlocksLightLVL3";
import LayoutBlocksLightNoLog from "./pages/LayoutBlocksLightNoLog";

type NewsT = {
  datetime: string;
  categories: string[];
  title: string;
  content: string;
  image: string;
  video: string;
}

function App(In:{CurrName: string;setCurrName: React.Dispatch<React.SetStateAction<string>>;DisplayedNews: {item: NewsT;index: number;}[];setDisplayedNews: React.Dispatch<React.SetStateAction<{item: NewsT;index: number;}[]>>;DisplayedTitle: NewsT;setDisplayedTitle: React.Dispatch<React.SetStateAction<NewsT>>;setNews: (value: React.SetStateAction<NewsT[]>) => void;switchDarkMode: ()=>void; darkMode: boolean; News:NewsT[]; LogedIn: boolean; l: number, loading:boolean, cats:{"name":string;"id":string}[],setLoading: (value: React.SetStateAction<boolean>) => void,setCats: (value: React.SetStateAction<{name: string;id: string;}[]>) => void,}) {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag: HTMLMetaElement | null = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route
        path="/"
        element={
          In.LogedIn?
            In.l===3?
              <LayoutBlocksLight3
                darkMode={In.darkMode}
                switchDarkMode={In.switchDarkMode}
                ProfileData={{
                  Email:"A@gmail.com",
                  TZ:"UTC+02:00",
                  Lang:"English",
                  Curr:"USD"
                }}
                News={In.News}
                loading={In.loading}
                cats={In.cats}
                setCats={In.setCats}
                setLoading={In.setLoading}
                setNews={In.setNews}
                CurrName={In.CurrName}
                setCurrName={In.setCurrName}
                DisplayedNews={In.DisplayedNews}
                setDisplayedNews={In.setDisplayedNews}
                DisplayedTitle={In.DisplayedTitle}
                setDisplayedTitle={In.setDisplayedTitle}
              />
            :In.l===2?
              <LayoutBlocksLight2
                darkMode={In.darkMode}
                switchDarkMode={In.switchDarkMode}
                ProfileData={{
                  Email:"A@gmail.com",
                  TZ:"UTC+02:00",
                  Lang:"English",
                  Curr:"USD"
                }}
                News={In.News}
                loading={In.loading}
                cats={In.cats}
                setCats={In.setCats}
                setLoading={In.setLoading}
                setNews={In.setNews}
                CurrName={In.CurrName}
                setCurrName={In.setCurrName}
                DisplayedNews={In.DisplayedNews}
                setDisplayedNews={In.setDisplayedNews}
                DisplayedTitle={In.DisplayedTitle}
                setDisplayedTitle={In.setDisplayedTitle}
              />
            :In.l===1?
              <LayoutBlocksLight1
                darkMode={In.darkMode}
                switchDarkMode={In.switchDarkMode}
                ProfileData={{
                  Email:"A@gmail.com",
                  TZ:"UTC+02:00",
                  Lang:"English",
                  Curr:"USD"
                }}
                News={In.News}
                loading={In.loading}
                cats={In.cats}
                setCats={In.setCats}
                setLoading={In.setLoading}
                setNews={In.setNews}
                CurrName={In.CurrName}
                setCurrName={In.setCurrName}
                DisplayedNews={In.DisplayedNews}
                setDisplayedNews={In.setDisplayedNews}
                DisplayedTitle={In.DisplayedTitle}
                setDisplayedTitle={In.setDisplayedTitle}
              />
            :
            <LayoutBlocksLight0
              darkMode={In.darkMode}
              switchDarkMode={In.switchDarkMode}
              ProfileData={{
                Email:"A@gmail.com",
                TZ:"UTC+02:00",
                Lang:"English",
                Curr:"USD"
              }}
              News={In.News}
              loading={In.loading}
              cats={In.cats}
              setCats={In.setCats}
              setLoading={In.setLoading}
              setNews={In.setNews}
              CurrName={In.CurrName}
              setCurrName={In.setCurrName}
              DisplayedNews={In.DisplayedNews}
              setDisplayedNews={In.setDisplayedNews}
              DisplayedTitle={In.DisplayedTitle}
              setDisplayedTitle={In.setDisplayedTitle}
            />
          :
          <LayoutBlocksLightNoLog
            darkMode={In.darkMode}
            switchDarkMode={In.switchDarkMode}
            ProfileData={{
              Email:"A@gmail.com",
              TZ:"UTC+02:00",
              Lang:"English",
              Curr:"USD"
            }}
            News={In.News}
            loading={In.loading}
            cats={In.cats}
            DisplayedNews={In.DisplayedNews}
            setDisplayedNews={In.setDisplayedNews}
            DisplayedTitle={In.DisplayedTitle}
            setDisplayedTitle={In.setDisplayedTitle}
          />
        }
      />
    </Routes>
  );
}
export default App;