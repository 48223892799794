import { FunctionComponent } from "react";
import styles from "./Download.module.css";

const Download: FunctionComponent = () => {
  return (
    <div className={styles.download}>
      <div className={styles.downloadApp}>Download app:</div>
      <img
        className={styles.googlePlayEng}
        loading="eager"
        alt=""
        src="/google-play--eng.svg"
      />
      <img
        className={styles.appStoreEng}
        loading="eager"
        alt=""
        src="/app-store--eng.svg"
      />
    </div>
  );
};

export default Download;
