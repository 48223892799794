import { FunctionComponent, useState, useCallback, useEffect, useRef } from "react";
import StudyContainer1 from "../components/StudyContainer1";
import styled from "styled-components";
import Property1PrimaryProperty2 from "../components/Property1PrimaryProperty2";
import ScrollHolder from "../components/ScrollHolder";
import StateHoverIconno from "../components/StateHoverIconno";
import Property1NavigationPropert1 from "../components/Property1NavigationPropert1";
import Property1Default14 from "../components/Property1Default14";
import Property1Default13 from "../components/Property1Default13";
import Property1DefaultImage from "../components/Property1DefaultImage";
import StateDefaultEmptyYesChec1 from "../components/StateDefaultEmptyYesChec1";
import Property1Default6 from "../components/Property1Default6";
import StateemptyScrollno from "../components/StateemptyScrollno";
import BalanceContainer from "../components/BalanceContainer";
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { Property } from "csstype";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp, faChevronDown, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { io } from 'socket.io-client';

const Navigationmainb = styled.nav`
  margin: 0;
  background-color: var(--colors-dark-sidepanel);
  width: 16.38888888888889vw;
  height: 100.0vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) 0.0vw;
  box-sizing: border-box;
`;
const ContactUs = styled.h3`
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  line-height: 4.166666666666666vh;
  font-weight: 600;
  font-family: inherit;
`;
const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
const HereAreYour = styled.p`
  margin: 0;
  height: 45vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  };
  & table {
    border-spacing: 0;
  }
  & table th {
    background-color: var(--colors-grey-stroke);
    border: solid 1px var(--colors-purple-light);
  };
  & table td {
    border: solid 1px var(--colors-purple-light);
  };
`;
const Articles = styled.div`
  width: 46.3888889vw;
  height: 78.515625vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.0vh var(--padding-xsW);
  box-sizing: border-box;
  z-index: 0;
  overflow-y: auto;
  scrollbar-width: none;
  gap: 0.3vh 1vw;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const NewsImages = styled.div<{isVisible:boolean}>`
  width: 46.3888889vw;
  overflow: hidden;
  display: ${(p)=>(p.isVisible?"flex":"none")};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
  z-index: 1;
  top: 1vh;
`;
const NewsVariants = styled.section`
  width: 47.5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-9xlH) var(--padding-5xsW) var(--padding-baseH);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-base);
  z-index: 3;
`;
const Ls = styled.div`
  width: 49.166666666666664vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-77xlH) 0.0vw 0.0vh var(--padding-5xlW);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-5xs);
`;
const Hola = styled.h3`
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 2.5vh;
  font-weight: 600;
  font-family: inherit;
`;
const HolaParent = styled.div`
  position: absolute;
  top: 1.953125vh;
  left: 0.0vw;
  width: 27.77777777777778vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const HolaContainer = styled.div`
  position: absolute;
  top: 9.244791666666668vh;
  left: 0.0vw;
  font-size: var(--buttons-btn-input-size);
  line-height: 2.604166666666667vh;
  display: inline-block;
  width: 27.7777778vw;
`;
const TabMeaning = styled.section`
  position: relative;
  width: 27.7777778vw;
  height: 19.661458333333336vh;
  z-index: 1;
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  display: flex;
`;
const TabsChange = styled.div`
  border-radius: var(--corner-radius-xl);
  background-color: var(--colors-white);
  width: 31.11111111111111vw;
  height: 57.8125vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xsH) var(--padding-5xsW);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-4xs);
`;
const Rs = styled.div`
  width: 33vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) var(--padding-5xlW) 0;
  box-sizing: border-box;
  gap: var(--gap-5xl);
`;
const Page1Study = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const ContactForm = styled.div`
  position: absolute;
  top: 3.125vh;
  left: 50.83333333333333vw;
  border-radius: var(--br-base);
  background-color: var(--colors-white);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-grey-stroke);
  box-sizing: border-box;
  width: 31.11111111111111vw;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) var(--padding-5xlW);
  gap: var(--gap-5xl);
`;
const Email1 = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
  display: flex;
  align-items: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 10.416666666666668vw;
  height: 4.6875vh;
  flex-shrink: 0;
`;
const Email = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xsH) 0.0vw;
  gap: var(--gap-5xl);
`;
const Russian = styled.div`
  position: relative;
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;
const Utc0200Parent = styled.div`
  flex: 1;
  height: 4.6875vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;
const Fields = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-5xs);
`;
const SmalldeleteIcon = styled.img`
  position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
`;
const SaveSession = styled.div`
  position: relative;
  font-size: var(--body-main-size);
  line-height: 2.604166666666667vh;
  font-weight: 500;
  font-family: var(--buttons-btn-input);
  color: var(--colors-red);
  text-align: left;
`;
const Buttonsecondary = styled.button`
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-red);
  padding: 0.0vh var(--padding-xsW);
  background-color: transparent;
  border-radius: var(--br-xs);
  box-sizing: border-box;
  height: 4.166666666666666vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
  cursor: pointer;
  & img {
    filter: var(--filters-red);
  }
  &:hover {
    background-color: var(--colors-red);
    & div {
      color: var(--colors-white);
    }
    & img {
      filter: var(--filters-btn-txt);
    }
  }
`;
const Container1 = styled.div`
  position: absolute;
  top: 3.125vh;
  left: 1.6666666666666667vw;
  border-radius: var(--br-xs);
  width: 47.5vw;
  height: 93.75vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-21xl);
  font-size: var(--body-main-size);
`;
const Page4Profile = styled.section`
  position: relative;
  width: 83.61111111111111vw;
  height: 100.0vh;
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  display: none
`;
const BlocksOfPages = styled.main`
  width: 83.61111111111111vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const LayoutBlocksLightRoot = styled.div`
  position: relative;
  background-color: var(--colors-grey-bg);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  & ::selection {
    background: var(--colors-green);
  }
`;

const CustCh = styled.div`
  cursor: pointer;
  user-select: none;
  width: min(1.5625vw,3.23624595vh);
  height: min(1.5625vw,3.23624595vh);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #333;
  border-radius: 5px;
  &:focus {
    outline: none;
    border-color: blue;
  }
`

const prepareDataForServer = ({
  assetGroupsOnClick,
  practicalConsiderationsOnClick,
}:{
  assetGroupsOnClick: { [key: string]: boolean };
  practicalConsiderationsOnClick: { [key: string]: boolean };
}) => {
  // Structuring the onClick states for asset groups and practical considerations
  const formattedAssetGroupsOnClick = Object.entries(assetGroupsOnClick).reduce((acc: {[key: string]: boolean}, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  const formattedPracticalConsiderationsOnClick = Object.entries(practicalConsiderationsOnClick).reduce((acc: {[key: string]: boolean}, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});

  // Prepare the complete data structure for server
  const dataToServer = {
    GroupActives: formattedAssetGroupsOnClick,
    FactorActives: formattedPracticalConsiderationsOnClick
  };

  return JSON.stringify(dataToServer);
};

interface CustomCheckboxProps {
  ariaLabel?: string;  // Optional prop for passing an accessible label
  currentVal: boolean;
  onToggle: () => void;
}

const CustomCheckbox: FunctionComponent<CustomCheckboxProps> = ({ ariaLabel = "Custom checkbox", currentVal = true, onToggle }) => {
  const [isChecked, setIsChecked] = useState(currentVal);

  const toggleCheckbox = () => {
    onToggle()
    setIsChecked(!isChecked);
  };

  const handleKeyPress: React.KeyboardEventHandler<HTMLDivElement> = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      toggleCheckbox();
      event.preventDefault();  // Prevents the default action if it's in a form
    }
  };

  return (
    <CustCh
      onClick={(event)=>{event.stopPropagation();toggleCheckbox()}}
      onKeyDown={handleKeyPress}
      aria-checked={isChecked}
      role="checkbox"
      tabIndex={0}
      aria-label={ariaLabel}  // Provides an accessible name for the checkbox
    >
      {isChecked ? '✔️' : '❌'}
    </CustCh>
  );
};

// Styling constants
const tableWidth = '80vw';
const tableHeight = '91vh';
const headerHeight = '13vh'; // Example header height

const TrNoPad = styled.tr<{
height: Property.Height;
}>`
  & th {
    padding:0;
    border: solid 1px var(--colors-dark-1);
    height: ${(p)=>p.height};
    border-bottom: solid 1px var(--colors-dark-1);
  }
  & th:has( div[role="checkbox"][aria-checked=false]) {
    background-color:var(--color-gray-400)
  }
`;
interface KeyMapping {
  [key: string]: string;
}

interface ActivationOrder {
  act: boolean;
  order: number;
}

interface GroupActivations {
  [key: string]: ActivationOrder | boolean;
}


interface JsonStructure {
  keys: KeyMapping;
  AGO: { [key: string]: ActivationOrder };
  FO: { [key: string]: ActivationOrder };
  Acts: { [key: string]: GroupActivations };
}

const AssetTable: FunctionComponent<{
  setLoading: (value: React.SetStateAction<boolean>) => void;
  setNews: (value: React.SetStateAction<TitleT[]>) => void;
  setCats: (value: React.SetStateAction<{name: string;id: string;}[]>) => void;
  recalculateScrollIndicator: () => Promise<void>;
  News: TitleT[];
  showCategory: (name: string) => void;
}> = (In:{
  setLoading: (value: React.SetStateAction<boolean>) => void,
  setNews: (value: React.SetStateAction<TitleT[]>) => void,
  setCats: (value: React.SetStateAction<{name: string;id: string;}[]>) => void,
  recalculateScrollIndicator: () => Promise<void>,
  News: TitleT[],
  showCategory: (name: string) => void,
}) => {

  let data=window.INITIAL_DATA || {}
  const [Preference, __] = useState<JsonStructure>(data.pref);

  interface ActivationOrder {
    act: boolean;
    order: number; // Assuming this is used for something like sorting or priority
  }
  
  interface Categories {
    [key: string]: ActivationOrder;
  }
  
  interface KeysMapping {
    [key: string]: string;
  }

  type OnClickStateHookReturnType = [
    { [key: string]: boolean }, // State object with keys mapped to boolean values
    (fullName: string) => void  // Toggle function for the state
  ];

  function useCreateOnClickStates(categories: Categories, keysMapping: KeysMapping): OnClickStateHookReturnType {
    // Initialize the state with transformed keys and activation statuses
    const initialState = Object.entries(categories).reduce((acc, [key, value]) => {
      const fullName = keysMapping[key] || key;  // Use the full name if available, otherwise use the key itself
      acc[fullName] = value.act;  // Initialize with the 'act' value
      return acc;
    }, {} as { [key: string]: boolean });
  
    const [state, setState] = useState<{ [key: string]: boolean }>(initialState);
  
    // Function to toggle the state by full name
    const toggleState = (fullName: string) => {
      setState(prevState => ({
        ...prevState,
        [fullName]: !prevState[fullName]
      }));
    };
  
    return [state, toggleState];
    
  }
  const [assetGroupsOnClick, toggleAssetGroup] = useCreateOnClickStates(Preference.AGO, Preference.keys);
  const [practicalConsiderationsOnClick, togglePracticalConsideration] = useCreateOnClickStates(Preference.FO, Preference.keys);

  const CreateSwitchAG = (assetG: string) => {
    const SwitchAG = () => {
      toggleAssetGroup(assetG);
    }
    return SwitchAG
  }

  const CreateSwitchPC = (pc: string) => {
    const SwitchPC = () => {
      togglePracticalConsideration(pc);
    }
    return SwitchPC
  }

  const GetAG = (assetG: string) => {
    return assetGroupsOnClick[assetG];
  }

  const GetPC = (pc: string) => {
    return practicalConsiderationsOnClick[pc];
  }

  const assetGroupsSrc = Object.entries(Preference.AGO)
    .sort(([_, a], [__, b]) => a.order - b.order)
    .map(([key]) => Preference.keys[key]);

  const practicalConsiderationsSrc = Object.entries(Preference.FO)
    .sort(([_, a], [__, b]) => a.order - b.order)
    .map(([key]) => Preference.keys[key]);

  const initialAssetsSrc: { [key: string]: { id: string, name: string, enabled: boolean }[] } = {};

  for (const [groupKey, assets] of Object.entries(Preference.Acts)) {
    initialAssetsSrc[Preference.keys[groupKey]] = Object.entries(assets)
    .filter(([key, value]) => typeof value === 'object')
    .sort(([_, a], [__, b]) => (a as ActivationOrder).order - (b as ActivationOrder).order)
    .map(([key, val]) => ({
      id: key,  // Strip the group prefix from the id
      name: Preference.keys[key],
      enabled: typeof val === 'boolean' ? val : val.act
    }));
  }



  const [highlightedRow, setHighlightedRow] = useState<string | null>(null);
  const [practicalConsiderations, ___] = useState(practicalConsiderationsSrc);
  const [assetGroups, ____] = useState(assetGroupsSrc);

  const highlightRow = (rowName: string|null) => {
    setHighlightedRow(rowName);
  };

  const SendToServ = () => {
    const serverData = prepareDataForServer({
      assetGroupsOnClick: assetGroupsOnClick,
      practicalConsiderationsOnClick: practicalConsiderationsOnClick,
    });
    fetch("/UpdPref",{
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin", // include, *same-origin, omit
      headers: {
        "Content-Type": "application/json",
      },
      body: serverData
    }).then(async ()=>{
      let currentsort = ""
      let currentdir = ""
      let currentview = ""
      const viewValue = (document.getElementById("UIView") as HTMLSelectElement).value
      const sortVal = (document.getElementById("UISort") as HTMLSelectElement).value
      switch (sortVal) {
        case "Date Ascending":
          currentsort="date"
          currentdir="asc"
          break
        case "Date Descending":
          currentsort="date"
          currentdir="desc"
          break
        case "Internal Rating Ascending":
          currentsort="rating"
          currentdir="asc"
          break
        case "Internal Rating Descending":
          currentsort="rating"
          currentdir="desc"
          break
        case "Internal Views Ascending":
          currentsort="views"
          currentdir="asc"
          break
        case "Internal Views Descending":
          currentsort="views"
          currentdir="desc"
          break
        default:
          return
      }
      switch (viewValue) {
        case "Assets":
          currentview = "assets"
          break
        case "Factors":
          currentview = "factors"
          break
        default:
          return
      }
      let amounts=In.News.reduce((acc, item) => {
        const category = item.categories[0];
        if (category) {
          acc[category] = acc[category] ? acc[category] + 1 : 1;
        }
        return acc;
      }, {} as {[key: string]:number});
      In.setLoading(true);
      try {
        const response = await fetch('/feed', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mode: 'update', sort:currentsort, dir:currentdir, view:currentview, amounts:amounts }),
        });
        const data: {"set":TitleT[], "cats":{"name":string;"id":string}[]} = await response.json();
        In.setNews(data["set"]);
        In.setCats(data["cats"]);
        In.showCategory("GEN")
        const element = document.getElementById("GENID") as HTMLElement;
        requestAnimationFrame(() => {
          if (element.parentElement) {
            const el = element.parentElement.querySelector(".selected")
            if (el) {
              // Remove the 'selected' class
              el.classList.remove("selected");
            }
            element.classList.add("selected")
          }
        });
        await In.recalculateScrollIndicator()
      } catch (error) {
        console.error('Error fetching news:', error);
      } finally {
        In.setLoading(false);
      }
    })
  }
  return (
    <table style={{ width: tableWidth, height: tableHeight, borderSpacing: 0, borderCollapse: "collapse", position: "relative", top: "3vh", left: "2vw"}}>
      <thead>
        <TrNoPad
          height= {headerHeight}
        >
          <th style={{ height: headerHeight, width: "25%"}}>
            <div style={{display: "inline-flex", gap: "1vw", width: "100%"}}>
              <StateHoverIconno
                saveSession="Save"
                stateHoverIconnoJustifyContent="flex-start"
                stateHoverIconnoWidth="25%"
                stateHoverIconnoZIndex="unset"
                stateHoverIconnoBackgroundColor="transparent"
                stateHoverIconnoCursor="pointer"
                stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
                stateHoverIconnoBorderC="var(--colors-darkerblue)"
                stateHoverIconnoBorderS="solid"
                stateHoverIconnoHeight="4.166666666666666vh"
                stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                stateHoverIconnoBoxSizing="border-box"
                stateHoverIconnoPosition="relative"
                stateHoverIconnoTop="unset"
                stateHoverIconnoLeft="25%"
                saveSessionColor="var(--colors-darkerblue)"
                saveSessionDisplay="inline-block"
                onButtonSecondaryClick={SendToServ}
              />
              <StateHoverIconno
                saveSession="Help"
                stateHoverIconnoJustifyContent="flex-start"
                stateHoverIconnoWidth="25%"
                stateHoverIconnoZIndex="unset"
                stateHoverIconnoBackgroundColor="transparent"
                stateHoverIconnoCursor="pointer"
                stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
                stateHoverIconnoBorderC="var(--colors-darkerblue)"
                stateHoverIconnoBorderS="solid"
                stateHoverIconnoHeight="4.166666666666666vh"
                stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                stateHoverIconnoBoxSizing="border-box"
                stateHoverIconnoPosition="relative"
                stateHoverIconnoTop="unset"
                stateHoverIconnoLeft="25%"
                saveSessionColor="var(--colors-darkerblue)"
                saveSessionDisplay="inline-block"
                onButtonSecondaryClick={()=>alert("TBA")}
              />
            </div>
          </th>
          {assetGroups.map((group, index) => (
            <th
              key={group}
              style={{
                width: "10vw",
                textAlign: "center",
                verticalAlign: "top",
                fontSize: "var(--body-main-size)",
              }}
              data-group={group}
            >
              <div style={{width: "90%",justifyContent: "space-between",display: "flex",paddingLeft: "5%",paddingRight: "5%",paddingTop:"0.5vh"}}><FontAwesomeIcon icon={faChevronLeft} /><FontAwesomeIcon icon={faChevronRight} /></div>
              <CustomCheckbox ariaLabel={group+"CB"} currentVal={GetAG(group)} onToggle={CreateSwitchAG(group)}/>
              <div>
                {group}
              </div>
            </th>
          ))}
        </TrNoPad>
      </thead>
      <tbody>
        {practicalConsiderations.map((consideration, index) => (
          <tr
          key={consideration}
          style={{
            height: "calc(13vh + 2px)",
          }}
          >
            <td
              style={{height: "13vh", minHeight: "13vh", maxHeight: "13vh", backgroundColor:GetPC(consideration)?(consideration===highlightedRow?"var(--colors-green)":"initial"):"var(--color-gray-400)", border: "solid 1px var(--colors-dark-1)"}}
              onClick={(event)=>{if(GetPC(consideration)){consideration===highlightedRow?highlightRow(null):highlightRow(consideration);event.stopPropagation();}}}>
                <FontAwesomeIcon icon={faChevronUp} style={{display: "block", width: "100%", position: "relative", bottom: "1vh"}} />
                <CustomCheckbox ariaLabel={consideration+"CB"} currentVal={GetPC(consideration)} onToggle={CreateSwitchPC(consideration)}/>
                {" " + consideration}
                <FontAwesomeIcon icon={faChevronDown} style={{display: "block", width: "100%", position: "relative", top: "1vh"}} />
            </td>
            {assetGroups.map((group, groupIndex) => (
              <td
              key={`${consideration}-${group}`}
              style={{
                backgroundColor: (GetPC(consideration) && GetAG(group))?(consideration===highlightedRow?"var(--colors-green)":"initial"):"var(--color-gray-400)",
                width: "10vw",
                borderLeft: "solid 1px var(--colors-dark-1)",
                borderRight: "solid 1px var(--colors-dark-1)",
                borderTop: "solid 1px var(--colors-dark-1)",
                borderBottom: "solid 1px var(--colors-dark-1)",
                textAlign:"center",
                height: "13vh",
              }}>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

let isWS = false

function WebSocketUpdate(In:{
  setLoading: (value: React.SetStateAction<boolean>) => void;
  setNews: (value: React.SetStateAction<TitleT[]>) => void;
  setCats: (value: React.SetStateAction<{name: string;id: string;}[]>) => void;
  recalculateScrollIndicator: () => Promise<void>;
  News: TitleT[];
  url: string;
  fetchUrl: string;
  showCategory: (name: string) => void;
}) {
  const fetchNewVideos = async () => {
    let currentsort = ""
    let currentdir = ""
    let currentview = ""
    const viewValue = (document.getElementById("UIView") as HTMLSelectElement).value
    const sortVal = (document.getElementById("UISort") as HTMLSelectElement).value
    switch (sortVal) {
      case "Date Ascending":
        currentsort="date"
        currentdir="asc"
        break
      case "Date Descending":
        currentsort="date"
        currentdir="desc"
        break
      case "Internal Rating Ascending":
        currentsort="rating"
        currentdir="asc"
        break
      case "Internal Rating Descending":
        currentsort="rating"
        currentdir="desc"
        break
      case "Internal Views Ascending":
        currentsort="views"
        currentdir="asc"
        break
      case "Internal Views Descending":
        currentsort="views"
        currentdir="desc"
        break
      default:
        return
    }
    switch (viewValue) {
      case "Assets":
        currentview = "assets"
        break
      case "Factors":
        currentview = "factors"
        break
      default:
        return
    }
    let amounts=In.News.reduce((acc, item) => {
      const category = item.categories[0];
      if (category) {
        acc[category] = acc[category] ? acc[category] + 1 : 1;
      }
      return acc;
    }, {} as {[key: string]:number});
    In.setLoading(true);
    try {
      const response = await fetch(In.fetchUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ mode: 'update', sort:currentsort, dir:currentdir, view:currentview, amounts:amounts }),
      });
      const data: {"set":TitleT[], "cats":{"name":string;"id":string}[]} = await response.json();
      In.setNews(data["set"]);
      In.setCats(data["cats"]);
      In.showCategory("GEN")
      const element = document.getElementById("GENID") as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
      await In.recalculateScrollIndicator()
    } catch (error) {
      console.error('Error fetching news:', error);
    } finally {
      In.setLoading(false);
    }
  };
  const socket = io(In.url);

  socket.on("newvid",(event) => {
    // Assuming the server sends a specific message type when new videos are available
    const data = JSON.parse(event.data);
    if (data.type === 'NEW_VIDEOS_AVAILABLE') {
      fetchNewVideos();
    }
  })
  return null; // Since this is a hook with no visual output
}

interface YouTubeVideoProps {
  videoId: string;
}

const YouTubeVideo: FunctionComponent<YouTubeVideoProps> = ({ videoId }) => {
  const src = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="video-responsive" style={{width:"31.11111111111111vw",height:"32.8125vh"}}>
      <iframe
        title={videoId+"Embed"}
        style={{width:"31.11111111111111vw",height:"32.8125vh",borderRadius: "var(--corner-radius-xl)",overflow: "hidden", border: "none"}}
        src={src}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

type TitleT = {
  datetime: string;
  categories: string[];
  title: string;
  content: string;
  image: string;
  video: string;
}

type LBL = {
  ProfileData: Prof;
  News: TitleT[];
  darkMode: boolean;
  switchDarkMode: ()=>void;
  loading: boolean;
  cats:{"name":string;"id":string}[];
  setLoading: (value: React.SetStateAction<boolean>) => void;
  setCats: (value: React.SetStateAction<{name: string;id: string;}[]>) => void;
  setNews:(value: React.SetStateAction<TitleT[]>) => void;
  CurrName: string;
  setCurrName: React.Dispatch<React.SetStateAction<string>>;
  DisplayedNews: {item: TitleT;index: number;}[];
  setDisplayedNews: React.Dispatch<React.SetStateAction<{item: TitleT;index: number;}[]>>;
  DisplayedTitle: TitleT;
  setDisplayedTitle: React.Dispatch<React.SetStateAction<TitleT>>;
}

type Prof = {
  Email: string;
  TZ: string;
  Lang: string;
  Curr: string;
}

const LayoutBlocksLight: FunctionComponent<LBL> = (InputData) => {

  const CreateScrollConnection = (key: string) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const scrollRef = useRef<HTMLDivElement>(null);
    const siblingRef = useRef<HTMLDivElement>(null);
    const [scrollIndicatorHeight, setScrollIndicatorHeight] = useState<string>('0%');
    const [scrollIndicatorTop, setScrollIndicatorTop] = useState<string>('0%');

    const calculateScrollIndicator = (scrollElement: HTMLDivElement | null) => {
      if (scrollElement) {
        const visibleRatio = scrollElement.clientHeight / scrollElement.scrollHeight;
        const indicatorHeight = `${visibleRatio * 100}%`;
        const scrollPercent = scrollElement.scrollTop / (scrollElement.scrollHeight - scrollElement.clientHeight);
        const indicatorTop = `${scrollPercent * (100 - parseFloat(indicatorHeight))}%`;

        return { indicatorHeight, indicatorTop };
      }
      return { indicatorHeight: '0%', indicatorTop: '0%' };
    };

    useEffect(() => {
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        setScrollPosition(target.scrollTop);
        // Assuming this handles the scrollable content
        if (target.scrollHeight > target.clientHeight) {
          const visibleRatio = target.clientHeight / target.scrollHeight;
          const indicatorHeight = `${visibleRatio * 100}%`;
          const scrollPercent = target.scrollTop / (target.scrollHeight - target.clientHeight);
          const indicatorTop = `${scrollPercent * (100 - parseFloat(indicatorHeight))}%`;

          setScrollIndicatorHeight(indicatorHeight);
          setScrollIndicatorTop(indicatorTop);
        }
      };

      const scrollElement = siblingRef.current; // Assuming siblingRef is the scrollable element

      const intervalId = setInterval(() => {
        const scrollElement = siblingRef.current;
        if (scrollElement) {
          clearInterval(intervalId);
          // Element is now available, attach the event listener
          scrollElement.addEventListener('scroll', handleScroll);
          (async () => {
            await new Promise(f => setTimeout(f, 1));
            const scrollElement = siblingRef.current; // or scrollRef.current, depending on which you're targeting
            const { indicatorHeight, indicatorTop } = calculateScrollIndicator(scrollElement);
            setScrollIndicatorHeight(indicatorHeight);
            setScrollIndicatorTop(indicatorTop);
          })()
        }
      }, 100);

      return () => {
        clearInterval(intervalId);
        if (scrollElement) {
          scrollElement.removeEventListener('scroll', handleScroll);
        }
      };
    }, [key]);

    return { scrollRef, siblingRef, scrollIndicatorHeight, scrollIndicatorTop, recalculateScrollIndicator: async () => {
      await new Promise(f => setTimeout(f, 1));
      const scrollElement = siblingRef.current; // or scrollRef.current, depending on which you're targeting
      const { indicatorHeight, indicatorTop } = calculateScrollIndicator(scrollElement);
      setScrollIndicatorHeight(indicatorHeight);
      setScrollIndicatorTop(indicatorTop);
    }};
  };

  const p2 = CreateScrollConnection("Category")

  const MeanScroll = CreateScrollConnection("Mean")

  const onTabPrimaryB2Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      showElement("NewsSection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
    },
    []
  );


  const showCategory = useCallback((name: string) => {
    InputData.setCurrName(name)
    const newsWithIndexes = InputData.News.map((item, index) => ({
      item,
      index
    }));

    // Then, filter this array based on the category name
    let N = newsWithIndexes.filter(({ item }) => item.categories.includes(name));
    InputData.setDisplayedNews(N)
    p2.recalculateScrollIndicator()
  },[InputData,p2])

  useEffect(()=>{(async ()=>{await p2.recalculateScrollIndicator()})()}, [InputData.loading, p2])
  useEffect(()=>{showCategory(InputData.CurrName)},[InputData.News,InputData.CurrName,showCategory])

  if (!isWS) {
    isWS=true
    WebSocketUpdate({showCategory:showCategory,url:"https://collectorassets.com/", fetchUrl:"/feed",setLoading:InputData.setLoading, setCats:InputData.setCats, setNews:InputData.setNews, News:InputData.News, recalculateScrollIndicator:p2.recalculateScrollIndicator});
  }
  const GenShowTitleDisp = (index: number) => {
    return () => {
      InputData.setDisplayedTitle(InputData.DisplayedNews[index].item)
      MeanScroll.recalculateScrollIndicator()
    }
  }

  const onButtonSecondary36Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onButtonSecondary37Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onButtonSecondary38Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      alert("!");
    },
    []
  );

  const onSideNavigationBClick1 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      showElement("StudySection");
      hideElement("ProfileSection");
      hideElement("PreferenceSection");
      hideElement("ForumSection");
      hideElement("BalanceSection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
    },
    []
  );

  const onSideNavigationB3Click1 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      hideElement("StudySection");
      showElement("ProfileSection");
      hideElement("PreferenceSection");
      hideElement("ForumSection");
      hideElement("BalanceSection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
    },
    []
  );
  const onSideNavigationBClick5 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      hideElement("StudySection");
      hideElement("ProfileSection");
      showElement("PreferenceSection");
      hideElement("ForumSection");
      hideElement("BalanceSection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
    },
    []
  );

  const onSideNavigationB3Click6 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      hideElement("StudySection");
      hideElement("ProfileSection");
      hideElement("PreferenceSection");
      showElement("ForumSection");
      hideElement("BalanceSection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
    },
    []
  );

  const onBalanceClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
    const hideElement = (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = "none";
      }
    };
    const showElement = (id: string) => {
      const element = document.getElementById(id);
      if (element) {
        element.style.display = "flex";
      }
    };

    hideElement("StudySection");
    hideElement("ProfileSection");
    hideElement("PreferenceSection");
    hideElement("ForumSection");
    showElement("BalanceSection");
    const element = e.currentTarget as HTMLElement;
    requestAnimationFrame(() => {
      if (element.parentElement && element.parentElement.parentElement) {
        const el = element.parentElement.parentElement.querySelector(".selected")
        if (el) {
          // Remove the 'selected' class
          el.classList.remove("selected");
        }
        element.classList.add("selected")
      }
    });
  }, []);

  const onSideNavigationB3Click1CU = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      hideElement("StudySection");
      showElement("ProfileSection");
      hideElement("PreferenceSection");
      hideElement("ForumSection");
      hideElement("BalanceSection");
      const element = document.getElementById("ProfileNav");
      requestAnimationFrame(() => {
        if (element && element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
    },
    []
  );

  const [isHoveredCU, setIsHoveredCU] = useState(false);
  const [isHoveredPP, setIsHoveredPP] = useState(false);
  const [isHoveredTC, setIsHoveredTC] = useState(false);

  const OnSortChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    let sort = ""
    let dir = ""
    let view = ""
    const viewVal = (document.getElementById("UIView") as HTMLSelectElement).value
    switch (newValue) {
      case "Date Ascending":
        sort="date"
        dir="asc"
        break
      case "Date Descending":
        sort="date"
        dir="desc"
        break
      case "Internal Rating Ascending":
        sort="rating"
        dir="asc"
        break
      case "Internal Rating Descending":
        sort="rating"
        dir="desc"
        break
      default:
        return
    }
    
    switch (viewVal) {
      case "Assets":
        view = "assets"
        break
      case "Factors":
        view = "factors"
        break
      default:
        return
    }
    let amounts=InputData.News.reduce((acc, item) => {
      const category = item.categories[0];
      if (category) {
        acc[category] = acc[category] ? acc[category] + 1 : 1;
      }
      return acc;
    }, {} as {[key: string]:number});
    InputData.setLoading(true);
    const fetchNews = async () => {
      try {
        const response = await fetch('/feed', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mode: 'sort', sort: sort, dir: dir, view: view, amounts: amounts }),
        });
        const data: {"set":TitleT[]} = await response.json();
        InputData.setNews(data["set"]);
        p2.recalculateScrollIndicator()
      } catch (error) {
        console.error('Error fetching news:', error);
      } finally {
        InputData.setLoading(false);
      }
    };

    fetchNews();
  }

  const OnViewChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    let sort = ""
    let dir = ""
    let view = ""
    const sortVal = (document.getElementById("UISort") as HTMLSelectElement).value
    switch (sortVal) {
      case "Date Ascending":
        sort="date"
        dir="asc"
        break
      case "Date Descending":
        sort="date"
        dir="desc"
        break
      case "Internal Rating Ascending":
        sort="rating"
        dir="asc"
        break
      case "Internal Rating Descending":
        sort="rating"
        dir="desc"
        break
      default:
        return
    }
    switch (newValue) {
      case "Assets":
        view = "assets"
        break
      case "Factors":
        view = "factors"
        break
      default:
        return
    }
    InputData.setLoading(true);
    const fetchNews = async () => {
      try {
        const response = await fetch('/feed', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mode: 'view', sort: sort, dir: dir, view: view }),
        });
        const data: {"set":TitleT[], "cats":{"name":string;"id":string}[]} = await response.json();
        InputData.setNews(data["set"]);
        InputData.setCats(data["cats"]);
        showCategory("GEN")
        const element = document.getElementById("GENID") as HTMLElement;
        requestAnimationFrame(() => {
          if (element.parentElement) {
            const el = element.parentElement.querySelector(".selected")
            if (el) {
              // Remove the 'selected' class
              el.classList.remove("selected");
            }
            element.classList.add("selected")
          }
        });
        p2.recalculateScrollIndicator()
      } catch (error) {
        console.error('Error fetching news:', error);
      } finally {
        InputData.setLoading(false);
      }
    };

    fetchNews();
  }

  const [LMVis, setLMVid] = useState<{[key:string]:boolean}>({"ALL":true, "GEN":false})

  const setLMVidCat = (cat: string, val: boolean)=>{
    setLMVid(prev=>{return {[cat]:val,...prev}})
  }

  const LoadMore = () => {
    const viewValue = (document.getElementById("UIView") as HTMLSelectElement).value
    let sort = ""
    let dir = ""
    let view = ""
    const sortVal = (document.getElementById("UISort") as HTMLSelectElement).value
    switch (sortVal) {
      case "Date Ascending":
        sort="date"
        dir="asc"
        break
      case "Date Descending":
        sort="date"
        dir="desc"
        break
      case "Internal Rating Ascending":
        sort="rating"
        dir="asc"
        break
      case "Internal Rating Descending":
        sort="rating"
        dir="desc"
        break
      default:
        return
    }
    switch (viewValue) {
      case "Assets":
        view = "assets"
        break
      case "Factors":
        view = "factors"
        break
      default:
        return
    }
    let tab = InputData.CurrName
    let start=InputData.DisplayedNews.length+1
    InputData.setLoading(true);
    const fetchNews = async () => {
      try {
        const response = await fetch('/feed', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ mode: 'load', sort: sort, dir: dir, view: view, tab: tab, start: start }),
        });
        const data: {"set":TitleT[]} = await response.json();
        InputData.setNews(prevNews => [...prevNews, ...data.set]);
        if (data["set"].length<10) {
          setLMVidCat(tab,false)
        }
        p2.recalculateScrollIndicator()
      } catch (error) {
        console.error('Error fetching news:', error);
      } finally {
        InputData.setLoading(false);
      }
    };

    fetchNews();
  }

  return (
    <LayoutBlocksLightRoot>
      <Navigationmainb>
        <StudyContainer1
          switchDarkMode={InputData.switchDarkMode}
          darkMode={InputData.darkMode}
          onSideNavigationBClick1={onSideNavigationBClick1}
          onSideNavigationB3Click1={onSideNavigationB3Click1}
          onBalanceClick={onBalanceClick}
          onSideNavigationBClick5={onSideNavigationBClick5}
          onSideNavigationB3Click6={onSideNavigationB3Click6}
        />
      </Navigationmainb>
      <BlocksOfPages>
        <Page1Study id="StudySection">
          <Ls id="Interactive-Element">
            <Property1PrimaryProperty2
              smallNews="/News.svg"
              property1PrimaryProperty2Width="calc(100% - 2.7777777777777777vw)"
              property1PrimaryProperty2Margin="0 !important"
              property1PrimaryProperty2Position="absolute"
              property1PrimaryProperty2Top="3.125vh"
              property1PrimaryProperty2Right="0.5555555555555556vw"
              property1PrimaryProperty2Left="2.2222222222222223vw"
              property1PrimaryProperty2ZIndex="0"
              property1PrimaryProperty2BorderBottom="0.13020833333333331vh solid var(--colors-grey-stroke)"
              onTabPrimaryB2Click={onTabPrimaryB2Click}
            >
              <Property1Default6
                id="UISort"
                property1DefaultWidth="9.791666666666666vw"
                property1DefaultJustifyContent="space-between"
                property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                property1DefaultGap="unset"
                property1DefaultPosition="relative"
                property1DefaultTop="1.5vh"
                property1DefaultLeft="none"
                property1DefaultBackgroundColor="transparent"
                property1DefaultFontFamily="Onest"
                property1DefaultFontWeight="500"
                property1DefaultFontSize="1.8229166666666667vh"
                property1DefaultColor="var(--colors-purple-light)"
                property1DefaultAlignSelf="unset"
                options={["Date Ascending","Date Descending","Internal Rating Ascending","Internal Rating Descending"]}
                value="Date Descending"
                OnChange={OnSortChange}
              />
              <Property1Default6
                id="UIView"
                property1DefaultWidth="9.791666666666666vw"
                property1DefaultJustifyContent="space-between"
                property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                property1DefaultGap="unset"
                property1DefaultPosition="relative"
                property1DefaultTop="1.5vh"
                property1DefaultLeft="none"
                property1DefaultBackgroundColor="transparent"
                property1DefaultFontFamily="Onest"
                property1DefaultFontWeight="500"
                property1DefaultFontSize="1.8229166666666667vh"
                property1DefaultColor="var(--colors-purple-light)"
                property1DefaultAlignSelf="unset"
                options={["Assets","Factors"]}
                value="Assets"
                OnChange={OnViewChange}
              />
            </Property1PrimaryProperty2>
            <NewsVariants id="NewsSection">
              <div>
                <Property1NavigationPropert1
                  Categories={InputData.cats}
                  showCategory={showCategory}
                  property1NavigationProperMargin="0 !important"
                  property1NavigationProperPosition="absolute"
                  property1NavigationProperTop="-2.5vh"
                  property1NavigationProperLeft="0.5555555555555556vw"
                  property1NavigationProperZIndex="2"
                />
                <NewsImages isVisible={true}>
                  <ScrollHolder
                    scrollHeight={p2.scrollIndicatorHeight}
                    scrollIndicatorTop={p2.scrollIndicatorTop}
                    ref={p2.scrollRef}
                    scrollHolderHeight="76.171875vh"
                    scrollHolderAlignItems="flex-start"
                    scrollHolderPosition="absolute"
                    scrollHolderTop="0.5208333333333333vh"
                    scrollHolderLeft="unset"
                    scrollHolderMargin="0 !important"
                    scrollHolderRight="0.0vw"
                    scrollHolderFlexShrink="0"
                    scrollHolderZIndex="1"
                    scrollHolderBackgroundColor="var(--colors-grey-stroke)"
                    scrollBackgroundColor="var(--colors-purple-light)"
                  />
                  <Articles ref={p2.siblingRef}>
                    <>
                      {
                        InputData.loading?
                        <div>Loading...</div>:
                        InputData.DisplayedNews.map((Title, index)=>{
                          return (index<2?
                            <Property1Default14
                              prop={Title.item.datetime}
                              title={Title.item.title}
                              text={Title.item.content.slice(0, 100)}
                              categories={Title.item.categories}
                              property1DefaultCursor="pointer"
                              frameDivBackgroundImage={"url('"+Title.item.image+"')"}
                              chipBackgroundColor="var(--colors-darkerblue)"
                              divColor="var(--colors-purple-dark)"
                              spainColor="var(--colors-purple-dark)"
                              cuidadoHaciendaEchaColor="var(--colors-dark-1)"
                              aunqueBizumCuentaColor="var(--colors-dark-1)"
                              onNewsCardLargeContainerClick={
                                GenShowTitleDisp(index)
                              }
                            />
                            :
                            <Property1Default13
                              prop={Title.item.datetime}
                              title={Title.item.title}
                              text={Title.item.content.slice(0, 100)}
                              categories={Title.item.categories}
                              property1DefaultCursor="pointer"
                              frameDivBackgroundImage={"url('"+Title.item.image+"')"}
                              chipBackgroundColor="var(--colors-darkerblue)"
                              divColor="var(--colors-purple-dark)"
                              spainColor="var(--colors-purple-dark)"
                              cuidadoHaciendaEchaColor="var(--colors-dark-1)"
                              aunqueBizumCuentaColor="var(--colors-dark-1)"
                              onNewsCardSmallContainerClick={
                                GenShowTitleDisp(index)
                              }
                            />
                          )
                        })
                      }
                      {(InputData.CurrName in LMVis?LMVis[InputData.CurrName]:LMVis["ALL"]) &&
                      <StateHoverIconno
                        saveSession="v Load More"
                        stateHoverIconnoJustifyContent="flex-start"
                        stateHoverIconnoWidth="unset"
                        stateHoverIconnoZIndex="unset"
                        stateHoverIconnoBackgroundColor="transparent"
                        stateHoverIconnoCursor="pointer"
                        stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
                        stateHoverIconnoBorderC="var(--colors-darkerblue)"
                        stateHoverIconnoBorderS="solid"
                        stateHoverIconnoHeight="4.166666666666666vh"
                        stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                        stateHoverIconnoBoxSizing="border-box"
                        stateHoverIconnoPosition="unset"
                        stateHoverIconnoTop="unset"
                        stateHoverIconnoLeft="unset"
                        saveSessionColor="var(--colors-darkerblue)"
                        saveSessionDisplay="inline-block"
                        onButtonSecondaryClick={LoadMore}
                      />
                      }
                      <div style={{marginTop:"0.1vh", fontFamily: "var(--buttons-btn-input)", borderRadius:"var(--corner-radius-l)", fontSize: "var(--body-main-size)", height:"4vh", paddingTop: "2vh", paddingLeft: "2%", paddingRight: "2%", backgroundColor:"var(--colors-grey-stroke)", marginLeft:"1%", width:"93%", display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <div
                          style={{
                            color:"var(--colors-dark-1)",
                            cursor: 'pointer',
                            textDecoration: isHoveredCU ? 'underline' : 'none'
                          }}
                          onMouseEnter={() => setIsHoveredCU(true)}
                          onMouseLeave={() => setIsHoveredCU(false)}
                          onClick={onSideNavigationB3Click1CU}
                        >
                          Contact Us
                        </div>
                        <a
                          style={{
                            color:"var(--colors-dark-1)",
                            cursor: 'pointer',
                            textDecoration: isHoveredPP ? 'underline' : 'none'
                          }}
                          href="/PP"
                          onMouseEnter={() => setIsHoveredPP(true)}
                          onMouseLeave={() => setIsHoveredPP(false)}
                        >
                          Privacy Policy
                        </a>
                        <a
                          style={{
                            color:"var(--colors-dark-1)",
                            cursor: 'pointer',
                            textDecoration: isHoveredTC ? 'underline' : 'none'
                          }}
                          href="/TC"
                          onMouseEnter={() => setIsHoveredTC(true)}
                          onMouseLeave={() => setIsHoveredTC(false)}
                        >
                          Terms and Conditions
                        </a>
                      </div>
                    </>
                  </Articles>
                </NewsImages>
              </div>
            </NewsVariants>
          </Ls>
          <Rs>
            {
              InputData.DisplayedTitle.video===""?
              <Property1DefaultImage
                property1DefaultImageProp="/graphicsholder@2x.png"
                property1DefaultIconWidth="31.11111111111111vw"
                property1DefaultIconHeight="32.8125vh"
                property1DefaultIconPosition="relative"
                property1DefaultIconFlexShrink="0"
                property1DefaultIconTop="unset"
                property1DefaultIconLeft="unset"
              />
              :
              <YouTubeVideo videoId={InputData.DisplayedTitle.video} />
            }
            <TabsChange>
              <TabMeaning id="MeaningSection">
                <HolaParent>
                  <Hola key="MeaningWord">{InputData.DisplayedTitle.title}</Hola>
                </HolaParent>
                <HolaContainer>
                  <HereAreYour key="MeaningMean" ref={MeanScroll.siblingRef}>
                    <ReactMarkdown children={InputData.DisplayedTitle.content} remarkPlugins={[gfm]}/>
                  </HereAreYour>
                  <ScrollHolder
                    scrollHeight={MeanScroll.scrollIndicatorHeight}
                    scrollIndicatorTop={MeanScroll.scrollIndicatorTop}
                    ref={MeanScroll.scrollRef}
                    scrollHolderHeight="45vh"
                    scrollHolderAlignItems="unset"
                    scrollHolderPosition="absolute"
                    scrollHolderTop="0"
                    scrollHolderLeft="28vw"
                    scrollHolderMargin="0"
                    scrollHolderRight="unset"
                    scrollHolderFlexShrink="unset"
                    scrollHolderZIndex="unset"
                    scrollHolderBackgroundColor="var(--colors-grey-stroke)"
                    scrollBackgroundColor="var(--colors-purple-light)"
                  />
                </HolaContainer>
              </TabMeaning>
            </TabsChange>
          </Rs>
        </Page1Study>
        <Page4Profile id="ProfileSection">
          <ContactForm>
            <ContactUs>Contact Us</ContactUs>
            <Property1Default6
              property1DefaultWidth="unset"
              property1DefaultJustifyContent="space-between"
              property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW) 0.52083333333vh"
              property1DefaultGap="unset"
              property1DefaultPosition="relative"
              property1DefaultTop="unset"
              property1DefaultLeft="unset"
              property1DefaultBackgroundColor="transparent"
              property1DefaultFontFamily="Onest"
              property1DefaultFontWeight="500"
              property1DefaultFontSize="1.8229166666666667vh"
              property1DefaultColor="var(--colors-purple-light)"
              property1DefaultAlignSelf="stretch"
              options={["General", "Help","Privacy"]}
              value={"General"}
            />
            <StateDefaultEmptyYesChec1
              text="Email"
              stateDefaultEmptyYesChecBoxSizing="border-box"
              stateDefaultEmptyYesChecOutline="none"
              stateDefaultEmptyYesChecFontFamily="Onest"
              stateDefaultEmptyYesChecFontSize="1.8229166666666667vh"
              stateDefaultEmptyYesChecFlexShrink="unset"
              stateDefaultEmptyYesChecColor="var(--colors-purple-light)"
              stateDefaultEmptyYesChecPadding="var(--padding-9xsH) var(--padding-9xlW) var(--padding-9xsH) var(--padding-5xsW)"
              stateDefaultEmptyYesChecFlex="unset"
              stateDefaultEmptyYesChecFontWeight="unset"
              stateDefaultEmptyYesChecPosition="unset"
              stateDefaultEmptyYesChecTop="unset"
              stateDefaultEmptyYesChecLeft="unset"
              stateDefaultEmptyYesChecWidth="unset"
              stateDefaultEmptyYesChecAlignSelf="stretch"
              stateDefaultEmptyYesChecBackgroundColor="var(--colors-white)"
              stateDefaultEmptyYesChecBorderW="0.130208333vh 0.0694444444vw"
              stateDefaultEmptyYesChecBorderC="var(--colors-grey-stroke)"
              stateDefaultEmptyYesChecBorderS="solid"
              IsInput={true}
            />
            <StateemptyScrollno
              stateemptyScrollnoBoxSizing="border-box"
              stateemptyScrollnoFontFamily="Onest"
              stateemptyScrollnoFontSize="1.8229166666666667vh"
              stateemptyScrollnoOutline="none"
              stateemptyScrollnoColor="var(--colors-purple-light)"
              stateemptyScrollnoFlex="unset"
              stateemptyScrollnoHeight="26.041666666666668vh"
              stateemptyScrollnoFlexShrink="0"
              stateemptyScrollnoBackgroundColor="var(--colors-white)"
              stateemptyScrollnoBorderW="0.130208333vh 0.0694444444vw"
              stateemptyScrollnoBorderC="var(--colors-grey-stroke)"
              stateemptyScrollnoBorderS="solid"
            />
            <StateHoverIconno
              saveSession="Send Message"
              stateHoverIconnoJustifyContent="flex-start"
              stateHoverIconnoWidth="unset"
              stateHoverIconnoZIndex="unset"
              stateHoverIconnoBackgroundColor="transparent"
              stateHoverIconnoCursor="pointer"
              stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
              stateHoverIconnoBorderC="var(--colors-darkerblue)"
              stateHoverIconnoBorderS="solid"
              stateHoverIconnoHeight="4.166666666666666vh"
              stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
              stateHoverIconnoBoxSizing="border-box"
              stateHoverIconnoPosition="unset"
              stateHoverIconnoTop="unset"
              stateHoverIconnoLeft="unset"
              saveSessionColor="var(--colors-darkerblue)"
              saveSessionDisplay="inline-block"
              onButtonSecondaryClick={onButtonSecondary36Click}
            />
          </ContactForm>
          <Container1>
            <Fields>
              <Email>
                <Email1>Time Zone</Email1>
                <Utc0200Parent>
                  <Russian>{InputData.ProfileData.TZ}</Russian>
                  <Property1Default6
                    property1DefaultWidth="9.791666666666666vw"
                    property1DefaultJustifyContent="space-between"
                    property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                    property1DefaultGap="unset"
                    property1DefaultPosition="absolute"
                    property1DefaultTop="0.5208333333333333vh"
                    property1DefaultLeft="25.694444444444443vw"
                    property1DefaultBackgroundColor="transparent"
                    property1DefaultFontFamily="Onest"
                    property1DefaultFontWeight="500"
                    property1DefaultFontSize="1.8229166666666667vh"
                    property1DefaultColor="var(--colors-purple-light)"
                    property1DefaultAlignSelf="unset"
                    options={["UTC+01:00","UTC+02:00"]}
                    value={InputData.ProfileData.TZ}
                  />
                </Utc0200Parent>
              </Email>
            </Fields>
            <FrameParent>
              <StateHoverIconno
                saveSession="Save Changes"
                stateHoverIconnoJustifyContent="flex-start"
                stateHoverIconnoWidth="unset"
                stateHoverIconnoZIndex="unset"
                stateHoverIconnoBackgroundColor="transparent"
                stateHoverIconnoCursor="pointer"
                stateHoverIconnoBorderW="0.130208333vh 0.0694444444vw"
                stateHoverIconnoBorderC="var(--colors-darkerblue)"
                stateHoverIconnoBorderS="solid"
                stateHoverIconnoHeight="4.166666666666666vh"
                stateHoverIconnoPadding="0.0vh var(--padding-xsW)"
                stateHoverIconnoBoxSizing="border-box"
                stateHoverIconnoPosition="unset"
                stateHoverIconnoTop="unset"
                stateHoverIconnoLeft="unset"
                saveSessionColor="var(--colors-darkerblue)"
                saveSessionDisplay="inline-block"
                onButtonSecondaryClick={onButtonSecondary37Click}
              />
              <Buttonsecondary
                disabled={true}
                onClick={onButtonSecondary38Click}
              >
                <SmalldeleteIcon alt="" src="/DeleteFC&Acc.svg" />
                <SaveSession>Delete Account</SaveSession>
              </Buttonsecondary>
            </FrameParent>
          </Container1>
        </Page4Profile>
        <Page4Profile id="PreferenceSection" style={{overflow: "hidden"}}>
          <AssetTable showCategory={showCategory} setLoading={InputData.setLoading} setCats={InputData.setCats} setNews={InputData.setNews} News={InputData.News} recalculateScrollIndicator={p2.recalculateScrollIndicator}/>
        </Page4Profile>
        <Page4Profile id="ForumSection">
        </Page4Profile>
        <BalanceContainer
          darkMode={InputData.darkMode}
          iconCode="/Checkmark.svg"
          propColor="var(--colors-dark-1)"
          propBorderTop="0.13020833333333331vh solid var(--colors-grey-stroke)"
          propColor1="var(--colors-dark-1)"
          propColor15="var(--colors-dark-1)"
          Active={1}
        />
      </BlocksOfPages>
    </LayoutBlocksLightRoot>
  );
};

export default LayoutBlocksLight;
