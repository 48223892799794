import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import Property1Default from "./Property1Default";
import styled from "styled-components";

type Property1Default14Type = {
  prop: string;
  title: string;
  text: string;
  categories: string[];
  /** Style props */
  property1DefaultCursor?: CSSProperties["cursor"];
  frameDivBackgroundImage?: CSSProperties["backgroundImage"];
  chipBackgroundColor?: CSSProperties["backgroundColor"];
  divColor?: CSSProperties["color"];
  spainColor?: CSSProperties["color"];
  cuidadoHaciendaEchaColor?: CSSProperties["color"];
  aunqueBizumCuentaColor?: CSSProperties["color"];

  /** Action props */
  onNewsCardLargeContainerClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const ChipWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 0.06944444444444445vw;
  flex-wrap: wrap;
`;
const Property1defaultInner = styled.div<{
  frameDivBackgroundImage?: Property.BackgroundImage;
}>`
  align-self: stretch;
  border-radius: var(--corner-radius-m);
  height: 22.916666666666664vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xsH) var(--padding-5xsW);
  box-sizing: border-box;
  background-image: url("/frame-441@3x.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-image: ${(p) => p.frameDivBackgroundImage};
`;
const Div = styled.div<{ divColor?: Property.Color }>`
  flex: 1;
  position: relative;
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(p) => p.divColor};
`;
const Parent1 = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-3xs);
  color: var(--colors-purple-dark);
`;
const CuidadoHaciendaEcha = styled.div<{
  cuidadoHaciendaEchaColor?: Property.Color;
}>`
  align-self: stretch;
  position: relative;
  font-size: var(--buttons-btn-input-size);
  font-weight: 500;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(p) => p.cuidadoHaciendaEchaColor};
`;
const AunqueBizumCuenta = styled.div<{
  aunqueBizumCuentaColor?: Property.Color;
}>`
  align-self: stretch;
  position: relative;
  font-size: var(--body-main-size);
  line-height: 2.604166666666667vh;
  display: -webkit-inline-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: ${(p) => p.aunqueBizumCuentaColor};
`;
const FrameParent = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.0vh var(--padding-xsW);
  gap: var(--gap-5xs);
`;
const Property1defaultRoot = styled.div<{
  property1DefaultCursor?: Property.Cursor;
}>`
  border-radius: var(--corner-radius-l);
  width: 21.666666666666668vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xsH) var(--padding-5xsW);
  box-sizing: border-box;
  gap: var(--gap-xs);
  text-align: left;
  font-size: var(--caption-cap-12-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  margin-top: 0.13020833333333331vh;
  cursor: ${(p) => p.property1DefaultCursor};
  &:hover {
    outline: 0.0694444444vmax solid var(--colors-blue2);
    background-color: var(--colors-white);
  }
`;

const Property1Default14: FunctionComponent<Property1Default14Type> = ({
  prop,
  title,
  text,
  categories,
  property1DefaultCursor,
  frameDivBackgroundImage,
  chipBackgroundColor,
  divColor,
  spainColor,
  cuidadoHaciendaEchaColor,
  aunqueBizumCuentaColor,
  onNewsCardLargeContainerClick,
}) => {
  return (
    <Property1defaultRoot
      property1DefaultCursor={property1DefaultCursor}
      onClick={onNewsCardLargeContainerClick}
    >
      <Property1defaultInner frameDivBackgroundImage={frameDivBackgroundImage}>
        <ChipWrapper>
          {categories.map((cat)=> {
            return (
              <Property1Default
                text={cat}
                property1DefaultFlexShrink="0"
                property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
                property1DefaultBoxSizing="border-box"
                property1DefaultCursor="unset"
                property1DefaultBorderW="unset"
                property1DefaultBorderS="unset"
                property1DefaultBorderC="unset"
                property1DefaultBackgroundColor="var(--colors-darkerblue)"
                allDisplay="inline-block"
                allAlignItems="unset"
                allJustifyContent="unset"
                allWidth="max-content"
                allHeight="1.5625"
                allFlexShrink="unset"
                allColor="var(--colors-white)"
              />
            )
          })}
        </ChipWrapper>
      </Property1defaultInner>
      <FrameParent>
        <Parent1>
          <Div divColor={divColor}>{prop}</Div>
        </Parent1>
        <CuidadoHaciendaEcha
          cuidadoHaciendaEchaColor={cuidadoHaciendaEchaColor}
        >
          {title}
        </CuidadoHaciendaEcha>
        <AunqueBizumCuenta aunqueBizumCuentaColor={aunqueBizumCuentaColor}>
          {text}
        </AunqueBizumCuenta>
      </FrameParent>
    </Property1defaultRoot>
  );
};

export default Property1Default14;
