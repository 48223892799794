import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import styled from "styled-components";

type Property1Default16Type = {
  smallWallet?: string;

  /** Style props */
  property1DefaultCursor?: CSSProperties["cursor"];
  property1DefaultBorderW?: CSSProperties["borderWidth"];
  property1DefaultBorderS?: CSSProperties["borderStyle"];
  property1DefaultBorderC?: CSSProperties["borderColor"];
  smallWalletIconOpacity?: CSSProperties["opacity"];
  balanceColor?: CSSProperties["color"];
  balanceDisplay?: CSSProperties["display"];
  balanceOpacity?: CSSProperties["opacity"];
  divDisplay?: CSSProperties["display"];
  divDisplay1?: CSSProperties["display"];

  /** Action props */
  onBalanceClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const SmallwalletIcon = styled.img<{
  smallWalletIconOpacity?: Property.Opacity;
}>`
  position: relative;
  --side: min(1.1111111111111112vw,2.083333333333333vh);
  width: var(--side);
  height: var(--side);
  object-fit: cover;
  opacity: 0.6;
  opacity: ${(p) => p.smallWalletIconOpacity};
  filter: var(--filters-purple-light);
`;
const Balance = styled.div<{
  balanceColor?: Property.Color;
  balanceDisplay?: Property.Display;
  balanceOpacity?: Property.Opacity;
}>`position: relative;
  line-height: 2.604166666666667vh;
  opacity: 0.6;
  color: ${(p) => p.balanceColor};
  display: ${(p) => p.balanceDisplay};
  opacity: ${(p) => p.balanceOpacity};
`;
const Left = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-9xs);
`;
const Div = styled.div<{ divDisplay?: Property.Display }>`
  position: relative;
  line-height: 2.604166666666667vh;
  display: ${(p) => p.divDisplay};
`;
const Div1 = styled.div<{ divDisplay1?: Property.Display }>`
  position: relative;
  line-height: 2.604166666666667vh;
  display: ${(p) => p.divDisplay1};
`;
const Amount = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--gap-11xs);
  color: var(--colors-btn-txt);
`;
const Property1defaultRoot = styled.div<{
  property1DefaultCursor?: Property.Cursor;
  property1DefaultBorderW?: Property.BorderWidth;
  property1DefaultBorderC?: Property.BorderColor;
  property1DefaultBorderS?: Property.BorderStyle;
}>`border-radius: var(--corner-radius-round);
  background-color: var(--colors-dark-2);
  width: 13.333333333333334vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xsH) var(--padding-smW);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  text-align: left;
  font-size: var(--body-main-size);
  color: var(--colors-white);
  font-family: var(--buttons-btn-input);
  cursor: ${(p) => p.property1DefaultCursor};
  border-width: ${(p) => p.property1DefaultBorderW};
  border-color: ${(p) => p.property1DefaultBorderC};
  border-style: ${(p) => p.property1DefaultBorderS};
`;

const Property1Default16: FunctionComponent<Property1Default16Type> = ({
  smallWallet,
  property1DefaultCursor,
  property1DefaultBorderW,
  property1DefaultBorderC,
  property1DefaultBorderS,
  smallWalletIconOpacity,
  balanceColor,
  balanceDisplay,
  balanceOpacity,
  divDisplay,
  divDisplay1,
  onBalanceClick,
}) => {
  return (
    <Property1defaultRoot
      property1DefaultCursor={property1DefaultCursor}
      property1DefaultBorderW={property1DefaultBorderW}
      property1DefaultBorderC={property1DefaultBorderC}
      property1DefaultBorderS={property1DefaultBorderS}
      onClick={onBalanceClick}
    >
      <Left>
        <SmallwalletIcon
          alt=""
          src={smallWallet}
          smallWalletIconOpacity={smallWalletIconOpacity}
        />
        <Balance
          balanceColor={balanceColor}
          balanceDisplay={balanceDisplay}
          balanceOpacity={balanceOpacity}
        >
          Subscription
        </Balance>
      </Left>
      <Amount>
        <Div divDisplay={divDisplay}>ACTIVE</Div>
      </Amount>
    </Property1defaultRoot>
  );
};

export default Property1Default16;
