import { FunctionComponent, type CSSProperties } from "react";
import { Property } from "csstype";
import Property1Default from "./Property1Default";
import styled from "styled-components";

type CatT = {
  name: string;
  id: string;
}

type Property1NavigationPropert1Type = {
  Categories: CatT[];
  /** Style props */
  property1NavigationProperMargin?: CSSProperties["margin"];
  property1NavigationProperPosition?: CSSProperties["position"];
  property1NavigationProperTop?: CSSProperties["top"];
  property1NavigationProperLeft?: CSSProperties["left"];
  property1NavigationProperZIndex?: CSSProperties["zIndex"];

  showCategory: (name: string) => void;
};

const Property1navigationPropertRoot = styled.div<{
  property1NavigationProperMargin?: Property.Margin;
  property1NavigationProperPosition?: Property.Position;
  property1NavigationProperTop?: Property.Top;
  property1NavigationProperLeft?: Property.Left;
  property1NavigationProperZIndex?: Property.ZIndex;
}>`width: 46.38888888888889vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-flow: wrap;
  justify-content: flex-start;
  padding: 0.0vh var(--padding-xsW);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  margin: ${(p) => p.property1NavigationProperMargin};
  position: ${(p) => p.property1NavigationProperPosition};
  top: ${(p) => p.property1NavigationProperTop};
  left: ${(p) => p.property1NavigationProperLeft};
  z-index: ${(p) => p.property1NavigationProperZIndex};
`;

const Property1NavigationPropert1: FunctionComponent<
  Property1NavigationPropert1Type
> = ({
  property1NavigationProperMargin,
  property1NavigationProperPosition,
  property1NavigationProperTop,
  property1NavigationProperLeft,
  property1NavigationProperZIndex,
  showCategory,
  Categories,
}) => {
  return (
    <Property1navigationPropertRoot
      property1NavigationProperMargin={property1NavigationProperMargin}
      property1NavigationProperPosition={property1NavigationProperPosition}
      property1NavigationProperTop={property1NavigationProperTop}
      property1NavigationProperLeft={property1NavigationProperLeft}
      property1NavigationProperZIndex={property1NavigationProperZIndex}
    >
      {
        Categories.map((Category)=> {
          const onChipClick = (e: React.MouseEvent<HTMLElement>) => {
            showCategory(Category.id)
            const element = e.currentTarget as HTMLElement;
            requestAnimationFrame(() => {
              if (element.parentElement) {
                const el = element.parentElement.querySelector(".selected")
                if (el) {
                  // Remove the 'selected' class
                  el.classList.remove("selected");
                }
                element.classList.add("selected")
              }
            });
          };
          return (
            <Property1Default
              text={Category.name}
              property1DefaultFlexShrink="0"
              property1DefaultPadding="var(--padding-9xsH) var(--padding-xsW)"
              property1DefaultBoxSizing="border-box"
              property1DefaultCursor="pointer"
              property1DefaultBorderW="unset"
              property1DefaultBorderC="unset"
              property1DefaultBorderS="unset"
              property1DefaultBackgroundColor="var(--colors-blue-1)"
              allDisplay="inline-block"
              allAlignItems="unset"
              allJustifyContent="unset"
              allWidth="max-content"
              allHeight="1.5625"
              allFlexShrink="unset"
              allColor="var(--colors-dark-1)"
              onChipClick={onChipClick}
              HasClass={Category.id==="GEN"?"selected":undefined}
              id={Category.id==="GEN"?"GENID":undefined}
            />
          )
        })
      }
    </Property1navigationPropertRoot>
  );
};

export default Property1NavigationPropert1;
