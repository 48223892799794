import { FunctionComponent, useState, useCallback, useEffect, useRef} from "react";
import StudyContainer1NoLog from "../components/StudyContainer1NoLog";
import styled from "styled-components";
import Property1PrimaryProperty2 from "../components/Property1PrimaryProperty2";
import ScrollHolder from "../components/ScrollHolder";
import Property1NavigationPropert1 from "../components/Property1NavigationPropert1";
import Property1Default14 from "../components/Property1Default14";
import Property1Default13 from "../components/Property1Default13";
import Property1DefaultImage from "../components/Property1DefaultImage";
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

const Navigationmainb = styled.nav`
  margin: 0;
  background-color: var(--colors-dark-sidepanel);
  width: 16.38888888888889vw;
  height: 100.0vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) 0.0vw;
  box-sizing: border-box;
`;
const HereAreYour = styled.p`
  margin: 0;
  height: 45vh;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  };
  & table {
    border-spacing: 0;
  }
  & table th {
    background-color: var(--colors-grey-stroke);
    border: solid 1px var(--colors-purple-light);
  };
  & table td {
    border: solid 1px var(--colors-purple-light);
  };
`;
const Articles = styled.div`
  width: 46.3888889vw;
  height: 78.515625vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.0vh var(--padding-xsW);
  box-sizing: border-box;
  z-index: 0;
  overflow-y: auto;
  scrollbar-width: none;
  gap: 0.3vh 1vw;
  -ms-overflow-style: none;
  ::-webkit-scrollbar {
    display: none;
  }
`;
const NewsImages = styled.div<{isVisible:boolean}>`
  width: 46.3888889vw;
  overflow: hidden;
  display: ${(p)=>(p.isVisible?"flex":"none")};
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  gap: var(--gap-3xs);
  z-index: 1;
  top: 1vh;
`;
const NewsVariants = styled.section`
  width: 47.5vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: var(--padding-9xlH) var(--padding-5xsW) var(--padding-baseH);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-base);
  z-index: 3;
`;
const Ls = styled.div`
  width: 49.166666666666664vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-77xlH) 0.0vw 0.0vh var(--padding-5xlW);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-5xs);
`;
const Hola = styled.h3`
  margin: 0;
  position: relative;
  font-size: inherit;
  line-height: 2.5vh;
  font-weight: 600;
  font-family: inherit;
`;
const HolaParent = styled.div`
  position: absolute;
  top: 1.953125vh;
  left: 0.0vw;
  width: 27.77777777777778vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-xs);
`;
const HolaContainer = styled.div`
  position: absolute;
  top: 9.244791666666668vh;
  left: 0.0vw;
  font-size: var(--buttons-btn-input-size);
  line-height: 2.604166666666667vh;
  display: inline-block;
  width: 27.7777778vw;
`;
const TabMeaning = styled.section`
  position: relative;
  width: 27.7777778vw;
  height: 19.661458333333336vh;
  z-index: 1;
  text-align: left;
  font-size: var(--body-message-size);
  color: var(--colors-dark-1);
  font-family: var(--buttons-btn-input);
  display: flex;
`;
const TabsChange = styled.div`
  border-radius: var(--corner-radius-xl);
  background-color: var(--colors-white);
  width: 31.11111111111111vw;
  height: 57.8125vh;
  overflow: hidden;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-5xsH) var(--padding-5xsW);
  box-sizing: border-box;
  position: relative;
  gap: var(--gap-4xs);
`;
const Rs = styled.div`
  width: 33vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xlH) var(--padding-5xlW) 0;
  box-sizing: border-box;
  gap: var(--gap-5xl);
`;
const Page1Study = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
`;
const BlocksOfPages = styled.main`
  width: 83.61111111111111vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;
const LayoutBlocksLightRoot = styled.div`
  position: relative;
  background-color: var(--colors-grey-bg);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  & ::selection {
    background: var(--colors-green);
  }
`;

interface YouTubeVideoProps {
  videoId: string;
}

const YouTubeVideo: FunctionComponent<YouTubeVideoProps> = ({ videoId }) => {
  const src = `https://www.youtube.com/embed/${videoId}`;

  return (
    <div className="video-responsive" style={{width:"31.11111111111111vw",height:"32.8125vh"}}>
      <iframe
        title={videoId+"Embed"}
        style={{width:"31.11111111111111vw",height:"32.8125vh",borderRadius: "var(--corner-radius-xl)",overflow: "hidden", border: "none"}}
        src={src}
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  );
};

type TitleT = {
  datetime: string;
  categories: string[];
  title: string;
  content: string;
  image: string;
  video: string;
}
type LBL = {
  ProfileData: Prof;
  News: TitleT[];
  darkMode: boolean;
  switchDarkMode: ()=>void;
  loading: boolean;
  cats:{"name":string;"id":string}[];
  DisplayedNews: {item: TitleT;index: number;}[];
  setDisplayedNews: React.Dispatch<React.SetStateAction<{item: TitleT;index: number;}[]>>;
  DisplayedTitle: TitleT;
  setDisplayedTitle: React.Dispatch<React.SetStateAction<TitleT>>;
}

type Prof = {
  Email: string;
  TZ: string;
  Lang: string;
  Curr: string;
}

const Close =styled.div`
  --side: min(2.2222222222222223vw,4.166666666666666vh);
  width: var(--side);
  height: var(--side);
  border-radius: var(--br-81xl);
  border-width: 0.130208333vh 0.0694444444vw;
  border-style: solid;
  border-color: var(--colors-darkerblue);
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-3xsH) var(--padding-8xsW);
  cursor: pointer;
`
const MediumcloseIcon =styled.img`
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
  --side: min(1.3888888888888888vw,2.604166666666667vh);
  width: var(--side);
  height: var(--side);
`
const LayoutBlocksLight: FunctionComponent<LBL> = (InputData) => {

  const CreateScrollConnection = (key: string) => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const scrollRef = useRef<HTMLDivElement>(null);
    const siblingRef = useRef<HTMLDivElement>(null);
    const [scrollIndicatorHeight, setScrollIndicatorHeight] = useState<string>('0%');
    const [scrollIndicatorTop, setScrollIndicatorTop] = useState<string>('0%');

    const calculateScrollIndicator = (scrollElement: HTMLDivElement | null) => {
      if (scrollElement) {
        const visibleRatio = scrollElement.clientHeight / scrollElement.scrollHeight;
        const indicatorHeight = `${visibleRatio * 100}%`;
        const scrollPercent = scrollElement.scrollTop / (scrollElement.scrollHeight - scrollElement.clientHeight);
        const indicatorTop = `${scrollPercent * (100 - parseFloat(indicatorHeight))}%`;

        return { indicatorHeight, indicatorTop };
      }
      return { indicatorHeight: '0%', indicatorTop: '0%' };
    };

    useEffect(() => {
      const handleScroll = (e: Event) => {
        const target = e.target as HTMLDivElement;
        setScrollPosition(target.scrollTop);
        // Assuming this handles the scrollable content
        if (target.scrollHeight > target.clientHeight) {
          const visibleRatio = target.clientHeight / target.scrollHeight;
          const indicatorHeight = `${visibleRatio * 100}%`;
          const scrollPercent = target.scrollTop / (target.scrollHeight - target.clientHeight);
          const indicatorTop = `${scrollPercent * (100 - parseFloat(indicatorHeight))}%`;

          setScrollIndicatorHeight(indicatorHeight);
          setScrollIndicatorTop(indicatorTop);
        }
      };

      const scrollElement = siblingRef.current; // Assuming siblingRef is the scrollable element

      const intervalId = setInterval(() => {
        const scrollElement = siblingRef.current;
        if (scrollElement) {
          clearInterval(intervalId);
          // Element is now available, attach the event listener
          scrollElement.addEventListener('scroll', handleScroll);
          (async () => {
            await new Promise(f => setTimeout(f, 1));
            const scrollElement = siblingRef.current; // or scrollRef.current, depending on which you're targeting
            const { indicatorHeight, indicatorTop } = calculateScrollIndicator(scrollElement);
            setScrollIndicatorHeight(indicatorHeight);
            setScrollIndicatorTop(indicatorTop);
          })()
        }
      }, 100);

      return () => {
        clearInterval(intervalId);
        if (scrollElement) {
          scrollElement.removeEventListener('scroll', handleScroll);
        }
      };
    }, [key]);

    return { scrollRef, siblingRef, scrollIndicatorHeight, scrollIndicatorTop, recalculateScrollIndicator: async () => {
      await new Promise(f => setTimeout(f, 1));
      const scrollElement = siblingRef.current; // or scrollRef.current, depending on which you're targeting
      const { indicatorHeight, indicatorTop } = calculateScrollIndicator(scrollElement);
      setScrollIndicatorHeight(indicatorHeight);
      setScrollIndicatorTop(indicatorTop);
    }};
  };

  const p2 = CreateScrollConnection("Category")

  const MeanScroll = CreateScrollConnection("Mean")

  const onTabPrimaryB2Click = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      showElement("NewsSection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
    },
    []
  );

  const showCategory = useCallback((name: string) => {
    const newsWithIndexes = InputData.News.map((item, index) => ({
      item,
      index
    }));

    // Then, filter this array based on the category name
    let N = newsWithIndexes.filter(({ item }) => item.categories.includes(name));
    InputData.setDisplayedNews(N)
    p2.recalculateScrollIndicator()
  },[InputData,p2])

  useEffect(()=>{(async ()=>{await p2.recalculateScrollIndicator()})()}, [InputData.loading, p2])

  const GenShowTitleDisp = (index: number) => {
    return () => {
      InputData.setDisplayedTitle(InputData.DisplayedNews[index].item)
      MeanScroll.recalculateScrollIndicator()
    }
  }

  const onSideNavigationBClick1 = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      const hideElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "none";
        }
      };
      const showElement = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
          element.style.display = "flex";
        }
      };
  
      showElement("StudySection");
      const element = e.currentTarget as HTMLElement;
      requestAnimationFrame(() => {
        if (element.parentElement) {
          const el = element.parentElement.querySelector(".selected")
          if (el) {
            // Remove the 'selected' class
            el.classList.remove("selected");
          }
          element.classList.add("selected")
        }
      });
    },
    []
  );

  const [isHoveredPP, setIsHoveredPP] = useState(false);
  const [isHoveredTC, setIsHoveredTC] = useState(false);
  const [LogInOpen, setLogInOpen] = useState(false);

  const OpenLogIn = () => {
    setLogInOpen(true)
  }
  const CloseLogIn = () => {
    setLogInOpen(false)
  }
  const SignIn = () => {
    var ch1 = (document.getElementById("TC") as HTMLInputElement).checked
    var ch2 = (document.getElementById("PP") as HTMLInputElement).checked
    var log = (document.getElementById("username") as HTMLInputElement).value
    var pwd = (document.getElementById("password") as HTMLInputElement).value
    var timezone='UTC+00:00'
    if (ch1 && ch2) {
      fetch('/regLog',
        {
          method:'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            'login':log,
            'password':pwd,
            'ch1':ch1,
            'ch2':ch2,
            'tz':timezone
          })
        }
      ).then(
        async text => {await text.text();window.location.reload()}//fix reload on error, remove Contact Us
      ).catch(
        error=>alert(error)
      )
    } else {
      alert("!")
    }
  }

  return (
    <LayoutBlocksLightRoot>
      {
        LogInOpen && <>
          <Close onClick={CloseLogIn} style={{position: "absolute", top: "20vh", left: "70vw", display: "flex", zIndex:99999999}}>
            <MediumcloseIcon
              loading="eager"
              alt=""
              src="/mediumclose1.svg"
            />
          </Close>
          <div style={{backgroundColor: "var(--colors-grey-bg04)", position: "absolute", top: "25vh", left: "40vw", display: "flex", flexDirection: "column",justifyContent:"center", width: "600px", height: "300px", borderRadius:"var(--corner-radius-l)", zIndex:99999999}}>
            <div style={{display: "flex", flexDirection: "row"}}>
              <div style={{width:"100%"}}>
                <h1 style={{fontSize:"30px",fontWeight:"300", color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)", width:"100%", justifyContent:"center", display:"flex"}}>Sign in/Register</h1>
              </div>
            </div>
            <div style={{display: "flex", flexDirection: "row",justifyContent: "center",gap: "1vw"}}>
              <div>
                <div>
                  <div>		          	
                    <label htmlFor="username" style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)"}}>Username</label>
                    <div>
                      <i className="fa fa-user"></i>
                      <input type="text" id="username" placeholder="" style={{fontFamily:"var(--buttons-btn-input)"}}></input>
                    </div>		            		            		            
                  </div>              
                </div>
                <div>
                  <div>
                    <label htmlFor="password" style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)"}}>Password</label>
                    <div>
                      <i className="fa fa-lock"></i>
                      <input type="password" id="password" placeholder="" style={{fontFamily:"var(--buttons-btn-input)"}}></input>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="btn btn-block btn-social btn-google-plus" onClick={()=>{alert("TBA")}} style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)",borderRadius: "var(--corner-radius-l)", width: "80%"}}>
                  <i className="fa fa-google-plus"></i> Sign in with Google
                </div>
                <div style={{marginTop:"10px", color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)"}}>
                  By accessing this Platform I accept:
                </div>
                <div style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)"}}>
                  <input type="checkbox" id="TC" style={{opacity: "0.6"}}></input>
                  <a href="/TC" style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)"}}>
                    Terms &  Conditions
                  </a> of this Platform
                </div>
                <div style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)"}}>
                  <input type="checkbox" id="PP" style={{opacity: "0.6"}}></input>
                  <a href="/PP" style={{color:"var(--colors-dark-1)", fontFamily:"var(--buttons-btn-input)"}}>
                    Privacy Policy
                  </a> of this Platform
                </div>
              </div>
            </div>
            <div style={{width:"100%", display:"flex", justifyContent:"center"}}>
              <div>
                <input type="button" onClick={SignIn} value="SIGN IN | REGISTER" style={{fontFamily:"var(--buttons-btn-input)", marginTop: "3vh"}}></input>
              </div>
            </div>
          </div>
        </>
      }
      {LogInOpen &&
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            backgroundColor: 'var(--color-black-350)',
            backdropFilter: 'blur(2px)',
            zIndex: 99999998,
          }} 
        />
      }
      <Navigationmainb>
        <StudyContainer1NoLog
          switchDarkMode={InputData.switchDarkMode}
          darkMode={InputData.darkMode}
          onSideNavigationBClick1={onSideNavigationBClick1}
          OpenLogIn={OpenLogIn}
        />
      </Navigationmainb>
      <BlocksOfPages>
        <Page1Study id="StudySection">
          <Ls id="Interactive-Element">
            <Property1PrimaryProperty2
              smallNews="/News.svg"
              property1PrimaryProperty2Width="calc(100% - 2.7777777777777777vw)"
              property1PrimaryProperty2Margin="0 !important"
              property1PrimaryProperty2Position="absolute"
              property1PrimaryProperty2Top="3.125vh"
              property1PrimaryProperty2Right="0.5555555555555556vw"
              property1PrimaryProperty2Left="2.2222222222222223vw"
              property1PrimaryProperty2ZIndex="0"
              property1PrimaryProperty2BorderBottom="0.13020833333333331vh solid var(--colors-grey-stroke)"
              onTabPrimaryB2Click={onTabPrimaryB2Click}
            />
            <NewsVariants id="NewsSection">
              <div>
                <Property1NavigationPropert1
                  Categories={InputData.cats}
                  showCategory={showCategory}
                  property1NavigationProperMargin="0 !important"
                  property1NavigationProperPosition="absolute"
                  property1NavigationProperTop="-2.5vh"
                  property1NavigationProperLeft="0.5555555555555556vw"
                  property1NavigationProperZIndex="2"
                />
                <NewsImages isVisible={true}>
                  <ScrollHolder
                    scrollHeight={p2.scrollIndicatorHeight}
                    scrollIndicatorTop={p2.scrollIndicatorTop}
                    ref={p2.scrollRef}
                    scrollHolderHeight="76.171875vh"
                    scrollHolderAlignItems="flex-start"
                    scrollHolderPosition="absolute"
                    scrollHolderTop="0.5208333333333333vh"
                    scrollHolderLeft="unset"
                    scrollHolderMargin="0 !important"
                    scrollHolderRight="0.0vw"
                    scrollHolderFlexShrink="0"
                    scrollHolderZIndex="1"
                    scrollHolderBackgroundColor="var(--colors-grey-stroke)"
                    scrollBackgroundColor="var(--colors-purple-light)"
                  />
                  <Articles ref={p2.siblingRef}>
                    <>
                      {
                        InputData.loading?
                        <div>Loading...</div>:
                        InputData.DisplayedNews.map((Title, index)=>{
                          return (index<2?
                            <Property1Default14
                              prop={Title.item.datetime}
                              title={Title.item.title}
                              text={Title.item.content.slice(0, 100)}
                              categories={Title.item.categories}
                              property1DefaultCursor="pointer"
                              frameDivBackgroundImage={"url('"+Title.item.image+"')"}
                              chipBackgroundColor="var(--colors-darkerblue)"
                              divColor="var(--colors-purple-dark)"
                              spainColor="var(--colors-purple-dark)"
                              cuidadoHaciendaEchaColor="var(--colors-dark-1)"
                              aunqueBizumCuentaColor="var(--colors-dark-1)"
                              onNewsCardLargeContainerClick={
                                GenShowTitleDisp(index)
                              }
                            />
                            :
                            <Property1Default13
                              prop={Title.item.datetime}
                              title={Title.item.title}
                              text={Title.item.content.slice(0, 100)}
                              categories={Title.item.categories}
                              property1DefaultCursor="pointer"
                              frameDivBackgroundImage={"url('"+Title.item.image+"')"}
                              chipBackgroundColor="var(--colors-darkerblue)"
                              divColor="var(--colors-purple-dark)"
                              spainColor="var(--colors-purple-dark)"
                              cuidadoHaciendaEchaColor="var(--colors-dark-1)"
                              aunqueBizumCuentaColor="var(--colors-dark-1)"
                              onNewsCardSmallContainerClick={
                                GenShowTitleDisp(index)
                              }
                            />
                          )
                        })
                      }
                      <div style={{marginTop:"0.1vh", fontFamily: "var(--buttons-btn-input)", borderRadius:"var(--corner-radius-l)", fontSize: "var(--body-main-size)", height:"4vh", paddingTop: "2vh", paddingLeft: "2%", paddingRight: "2%", backgroundColor:"var(--colors-grey-stroke)", marginLeft:"1%", width:"93%", display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <a
                          style={{
                            color:"var(--colors-dark-1)",
                            cursor: 'pointer',
                            textDecoration: isHoveredPP ? 'underline' : 'none'
                          }}
                          href="/PP"
                          onMouseEnter={() => setIsHoveredPP(true)}
                          onMouseLeave={() => setIsHoveredPP(false)}
                        >
                          Privacy Policy
                        </a>
                        <a
                          style={{
                            color:"var(--colors-dark-1)",
                            cursor: 'pointer',
                            textDecoration: isHoveredTC ? 'underline' : 'none'
                          }}
                          href="/TC"
                          onMouseEnter={() => setIsHoveredTC(true)}
                          onMouseLeave={() => setIsHoveredTC(false)}
                        >
                          Terms and Conditions
                        </a>
                      </div>
                    </>
                  </Articles>
                </NewsImages>
              </div>
            </NewsVariants>
          </Ls>
          <Rs>
            {
              InputData.DisplayedTitle.video===""?
              <Property1DefaultImage
                property1DefaultImageProp="/graphicsholder@2x.png"
                property1DefaultIconWidth="31.11111111111111vw"
                property1DefaultIconHeight="32.8125vh"
                property1DefaultIconPosition="relative"
                property1DefaultIconFlexShrink="0"
                property1DefaultIconTop="unset"
                property1DefaultIconLeft="unset"
              />
              :
              <YouTubeVideo videoId={InputData.DisplayedTitle.video} />
            }
            <TabsChange>
              <TabMeaning id="MeaningSection">
                <HolaParent>
                  <Hola key="MeaningWord">{InputData.DisplayedTitle.title}</Hola>
                </HolaParent>
                <HolaContainer>
                  <HereAreYour key="MeaningMean" ref={MeanScroll.siblingRef}>
                    <ReactMarkdown children={InputData.DisplayedTitle.content} remarkPlugins={[gfm]}/>
                  </HereAreYour>
                  <ScrollHolder
                    scrollHeight={MeanScroll.scrollIndicatorHeight}
                    scrollIndicatorTop={MeanScroll.scrollIndicatorTop}
                    ref={MeanScroll.scrollRef}
                    scrollHolderHeight="45vh"
                    scrollHolderAlignItems="unset"
                    scrollHolderPosition="absolute"
                    scrollHolderTop="0"
                    scrollHolderLeft="28vw"
                    scrollHolderMargin="0"
                    scrollHolderRight="unset"
                    scrollHolderFlexShrink="unset"
                    scrollHolderZIndex="unset"
                    scrollHolderBackgroundColor="var(--colors-grey-stroke)"
                    scrollBackgroundColor="var(--colors-purple-light)"
                  />
                </HolaContainer>
              </TabMeaning>
            </TabsChange>
          </Rs>
        </Page1Study>
      </BlocksOfPages>
    </LayoutBlocksLightRoot>
  );
};

export default LayoutBlocksLight;
