import { FunctionComponent } from "react";
import FrameComponent from "./FrameComponent";
import styles from "./ActionsContainer.module.css";

const ActionsContainer: FunctionComponent<{Back: ()=>void, darkMode: boolean}> = ({Back,darkMode}) => {
  return (
    <form className={styles.actionscontainer}>
      <div style={{color:"grey", fontSize:"1.953125vh", width: "100%", textAlign:"left", justifyContent:"space-between", display:"flex", flexDirection:"row"}}>
        <div onClick={Back} style={{cursor:"pointer", width:"fit-content"}}>{`< Back`}</div>
      </div>
      <div className={styles.paymentFormParent}>
        <h3 className={styles.paymentForm}>Payment Form</h3>
        <div className={styles.pleaseEnterYour}>
          Please enter your payment information below.
        </div>
      </div>
      <div className={styles.currentBalanceParent}>
        <div className={styles.currentBalance}>{`Current Balance: `}</div>
        <div className={styles.div}>0</div>
      </div>
      <div className={styles.addBalance}>Add Balance</div>
      <FrameComponent DC={false} darkMode={darkMode===true} />
      <button className={styles.buttonsecondary}>
        <div className={styles.saveSession}>Submit</div>
      </button>
    </form>
  );
};

export default ActionsContainer;
